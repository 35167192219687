import React, { Component } from 'react';
import {Redirect, Link, NavLink} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card} from 'react-bootstrap';
import {Api, DT_Academics, DT_QuickLinks} from '../../../utils/Variables';
import {upperCase, groupByMultiple} from '../../../utils';

import HEADER_3 from '../../../assets/img/header-3.png';
import TOP_DIVIDER from '../../../assets/img/top_divider_blue.png';

class PageHeader extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
	}
	
	componentDidMount(){
		
	}
	
	
	render(){
		
		
		return(
		<>
		{/*<header className="page-inner-header">
				<div className="h-100">
				  <div className=" h-100 align-items-center justify-content-center text-center">
						<img src={HEADER_3} style={{height: '160px', minWidth: '700px'}}  />
				 </div>						
				</div>	
				<img src={TOP_DIVIDER} />
		</header>	*/}
		</>
		);
	}
	
}
export default PageHeader;
