import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {Slider, CategoryWidget, FeaturedProduct, ProductCollection, LineLoader, NewsScroll} from '../../elements';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {getCookieField} from '../../../utils';
import {CircleSlowLoader} from '../../../elements';
import WOW from 'wowjs';

import PageHeader from '../Page/PageHeader';

const styles = {
	imgContainer: {
		border: '2px solid #8e8b8b',
		padding: '7px',
		borderRadius: '5px',
		cursor: 'pointer',
		backgroundColor: '#f1f1f1',
	}
}

class Gallery extends Component{
	constructor(props){
		super(props);
		this.state = {
			dataList: [],
			showLoader: true,
		}
	}
	
	componentDidMount(){
		
		this.loadGallery();
	}
	
	loadGallery(){
		try{
			this.setState({showLoader: true});
			API.get('site/v1/gallery/').then(res=>{
				if(res['data'].status=='1') {
					this.setState({dataList: res['data'].data, showLoader: false});
					new WOW.WOW().init();
				}
				else{
					this.setState({showLoader: false});
				}
			});						
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	showGalery(){
		try{
			var rv = [];
			this.state.dataList.map((item,i)=>{
				rv.push(<Col md={3} className="mt-15 wow slideInLeft" >
					<Link to={"/gallery/"+ item.slug} >
						<div className="staff-profile" >
							<div className="profile-image" >
								<figure>
									<img src={Api.ImageUrl + item.filepath} />
								</figure>
							</div>
							<div className="profile-info" >
								<div>{item.gallerytitle}</div>
							</div>
						</div>
					</Link>
					{/*<div style={styles.imgContainer} >
						<Link to={"/gallery/"+ item.slug} >
							<div>
								<div className="text-center" >
									<img src={Api.ImageUrl + item.filepath} style={{width: '100%'}} className="cl__image__bg"  />
									<h5 style={{fontSize: '16px', paddingTop: '10px'}} >{item.gallerytitle}</h5>
								</div>
							</div>
						</Link>
					</div>*/}
				</Col>);
			});
			return rv;
		}
		catch(error){ }
	}
	
	render(){
		
		
		return(
		<section>
				<PageHeader />
			
				<div  className="container page-in pb-50 shadow-lg  ">
					<Row>
						<Col md={12} >
							<div>
								<h3 className="text-center mt-10">&emsp;    Gallery</h3>
								<hr className="type_7" />
							</div>
						</Col>
					</Row>
					{this.state.showLoader ? <Row>
						<Col md={12} >
							<div className="text-center" >
								<CircleSlowLoader />
							</div>
						</Col>
					</Row> : <Row>
						{this.showGalery()}
						</Row>
					}
				</div>
			</section>
		);
	}
	
}
export default Gallery;
