import React, { Component, Suspense, lazy } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Link,
  HashRouter,
  IndexRoute,
  Redirect,
} from "react-router-dom";

import { Header, Header2, Sidebar, Footer } from "../views/elements";
import { Api } from "../utils/Variables";
import API from "../utils/API";
import Cookies from "universal-cookie";
import routes from "../views";
import { setls, removeBothSlash } from "../utils";
import WOW from "wowjs";

class SiteLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siteData: [],
    };
  }

  componentDidMount() {
    new WOW.WOW().init();
    this.loadSiteinfo();
  }

  loadSiteinfo() {
    try {
      API.get("v1/settings/me/1").then((res) => {
        if (res["data"].status == "1") {
          setls("site", res["data"].data);
          this.setState({ siteData: res["data"].data });
        }
      });
    } catch (error) {}
  }

  getType() {
    try {
      var path = window.location.pathname;
      var paths = removeBothSlash(path).trim().split("/");
      //return paths[1];
      return paths[1] == undefined ? "qnm" : paths[1];
    } catch (error) {
      return "qnm";
    }
  }

  render() {
    return (
      <div>
        <Header2 routes={routes} />

        <main id="main">
          <Switch>
            {routes.map((page, key) => (
              <Route
                exact
                path={page.path}
                component={page.component}
                key={key}
              />
            ))}
          </Switch>
        </main>

        <Footer />
      </div>
    );
  }
}
export default SiteLayout;
