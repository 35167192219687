import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {Slider, CategoryWidget, FeaturedProduct, ProductCollection, LineLoader} from '../../elements';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {getCookieField} from '../../../utils';

const styles = {
	imgContainer: {
		background: '#fff',
		textAlign: 'center',
		borderRadius: '30px 0px 30px',
		padding: '10px',
		marginRight: '20px',
		border: '1px solid #e8e3e3',
		minHeight: '301px',
	}
}

class ManagementMembers extends Component{
	constructor(props){
		super(props);
		this.state = {
			activeTab: 'vision',
		}
	}
	
	componentDidMount(){

	}
	
	
	
	render(){
		
		
		return(
			<section className="page-section bg-light pt-40 pb-60" >
				<div className="container mgt-user-container" >

					<Row>
						<Col md={12} >
							<h3 className="text-center" >OUR MANAGEMENT MEMBERS</h3>
							<hr className="type_1" />
						</Col>
						<Col md={2} className="px-0" >								
							<div style={styles.imgContainer} className="shadow-sm shadow-hover-xl" >
								<h5>President</h5>
								<img src="https://webadmin.tdmnscollege.edu.in/services/Public/uploads//website/management-members/president.jpg" />
								<div>Thiru R. K. Kalidhasan Nadar</div>
							</div>
						</Col>
						
						<Col md={2} className="px-0" >								
							<div style={styles.imgContainer} className="shadow-sm shadow-hover-xl" >
								<h5>Vice President</h5>
								<img src="https://webadmin.tdmnscollege.edu.in/services/Public/uploads//website/management-members/vice-president.jpg" />
								<div>Thiru. T.Murugesa Pandian Nadar</div>
							</div>
						</Col>
								
						<Col md={2} className="px-0" >								
							<div style={styles.imgContainer} className="shadow-sm shadow-hover-xl" >
								<h5>Secretary</h5>
								<img src="https://webadmin.tdmnscollege.edu.in/services/Public/uploads//website/management-members/secretary.jpg" />
								<div>Thiru. R. Shunmugavel Nadar</div>
							</div>
						</Col>
								
						<Col md={2} className="px-0" >								
							<div style={styles.imgContainer} className="shadow-sm shadow-hover-xl" >
								<h5>Joint Secretary</h5>
								<img src="https://webadmin.tdmnscollege.edu.in/services/Public/uploads//website/management-members/joint-secretary.jpg" />
								<div>Thiru. J.Rathinaraj Nadar</div>
							</div>
						</Col>
								
						<Col md={2} className="px-0" >								
							<div style={styles.imgContainer} className="shadow-sm shadow-hover-xl" >
								<h5>Treasurer</h5>
								<img src="https://webadmin.tdmnscollege.edu.in/services/Public/uploads//website/management-members/tresaure.jpg" />
								<div>Thiru. A.Selvaraj Nadar <br /></div>
							</div>
						</Col>
								
					</Row>
				</div>
			</section>
		);
	}
	
}
export default ManagementMembers;
