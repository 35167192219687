import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card} from 'react-bootstrap';
import {Api, DT_Academics, DT_Syllabus} from '../../../utils/Variables';
import {upperCase, groupByMultiple, getDescSortOrder} from '../../../utils';
import API from '../../../utils/API';
import {CircleSlowLoader} from '../../../elements';

import Sidebar from './Sidebar';
import PageHeader from './PageHeader';
import WOW from 'wowjs';


class Page extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
	}
	
	componentDidMount(){
		new WOW.WOW().init();
	}
	
	getField(fieldName){
		try{
			
			var d = this.props.pageData;
			return d[fieldName];
		}
		catch(error){
			console.log(error);
			return null;
		}
	}
	
	render(){
		
		
		return(
			  <div >
				
				
				{this.getField('pagetemplate')=='withsidebar' ? 
				<Row className="mt-0" >
					<Col md={9} >
						<div className=" py-10 px-20 bg-white"   >
							
							<h4 className="wow 	zoomInDown">{this.getField('pagetitle')}</h4>
							<hr />
							
							{/*<div className="share-btn">
								<ul>
									<li><a href="#"><i className="fa fa-facebook fb1"></i> Share On Facebook</a>
									</li>
									<li><a href="#"><i className="fa fa-twitter tw1"></i> Share On Twitter</a>
									</li>
									<li><a href="#"><i className="fa fa-google-plus gp1"></i> Share On Google Plus</a>
									</li>
								</ul>
							</div>*/}
							<Row>
								<Col md={12} >
									<div className="user-custom-page-content wow zoomInDown" >
										<div dangerouslySetInnerHTML={{__html: this.getField('pagecontent')}} className="pb-20"  />
									</div>
								</Col>
							</Row>
								
						</div>
						
					
					</Col>
					<Col md={3} >
						<div className="py-10 px-10 bg-white wow fadeInRight" >
							<Sidebar />
						</div>
						
					</Col>
				</Row>
				:
				<Row>
					<Col md={12} >
					
						<div className="shadow-lg mt-100 py-10 px-10 bg-white" style={{minHeight: '450px'}} >
							<h3 className="mt-0 wow fadeInDown">{this.getField('pagetitle')}</h3>
							{/*<div className="share-btn">
								<ul>
									<li><a href="#"><i className="fa fa-facebook fb1"></i> Share On Facebook</a>
									</li>
									<li><a href="#"><i className="fa fa-twitter tw1"></i> Share On Twitter</a>
									</li>
									<li><a href="#"><i className="fa fa-google-plus gp1"></i> Share On Google Plus</a>
									</li>
								</ul>
							</div>*/}
							<div dangerouslySetInnerHTML={{__html: this.getField('pagecontent')}} />
						</div>
					</Col>
				</Row>
				}
				
			</div>
		);
	}
	
}
export default Page;
