import Home from './pages/Home';
import Gallery from './pages/Gallery';
import GalleryList from './pages/Gallery/GalleryList';
import Empty from './pages/Page/Empty';
import NotFound from './pages/NotFound'
import Department from './pages/Department';
import ProgrammesByType from './pages/Programmes/ProgrammesByType';

const pageList = [
	{ name: 'Home', path: '/', component: Home }, 
	{ name: 'Programmes', path: '/programmes/:ptype', component: ProgrammesByType },
	{ name: 'Department', path: '/department/:cid/:cname', component: Department },
	{ name: 'Gallery', path: '/gallery', component: Gallery },
	{ name: 'Gallery', path: '/gallery/:slug', component: GalleryList },
	{ name: 'Empty', path: '/empty', component: Empty },
	{ name: 'Not Found', path: '*', component: NotFound },
];
export default pageList;