import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Table} from 'react-bootstrap';
import {CircleSlowLoader} from '../../../../elements';
import API from '../../../../utils/API';
import {groupByMultiple} from '../../../../utils';
import ReactModal from 'react-modal';
import Faculty from './index';

class FacultContainer extends Component{
	constructor(props){
		super(props);
		this.state = {
			showModal: false,
			showLoader: false,
			fileUrl: '',
			deptId : this.props.deptId,
			employeeList: [],
			employeeView: [],
		}
		
	}
	
	componentDidMount(){
		
		this.loadEmployeeList();
	}
	
	
	loadEmployeeList(){
		try{
			
			this.setState({showLoader: true});			
			API.get('site/v1/course/faculty/'+this.state.deptId).then(res=>{
				if(res['data'].status=='1'){
					var d = groupByMultiple(res['data'].data, function(obj){
						return[obj.emptype];
					});
					this.setState({showLoader: true, employeeList: res['data'].data, employeeView: d});
				}
				else{
					this.setState({showLoader: false});					
				}
			});			
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	
	render(){
	
		var deptId = this.props.deptId;
				
		return(
			 <div className="mt-30" >
				
				<Faculty facultyList={this.state.employeeList} />
				
			</div>
		);
	}
	
}
export default FacultContainer;
