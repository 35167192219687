import React, { Component } from 'react';
import {Redirect, Link, NavLink} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card} from 'react-bootstrap';
import {Api, DT_Academics, DT_Programmes} from '../../../utils/Variables';
import {upperCase, groupByMultiple, makeUrl} from '../../../utils';

class Sidebar extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
	}
	
	componentDidMount(){
		
	}
	
	
	render(){
		
		
		return(
			  <div>
				{this.props.deptList ? 
					<Row>
						<Col md={12} className="p-0" >									
							 <div className="de-left-tit">
								<h5 className="mmm-sidebar-menu-title" ><span className="ion-ios7-arrow-right" style={{
										color:'#fff',
										fontSize: '20px',
										fontWeight: '600',
										marginRight: '5px',
										}} ></span> DEPARTMENTS</h5>
										<hr />
							</div>
							<div className="ho-event">
								<ul className="mmm-sidebar-menu" >
								{this.props.deptList.map((item,i)=>{
									return <li key={i} >
											<NavLink to={'/department/'+item.id+'/'+makeUrl(item.departmentname)}  >
												{item.departmentname}
											</NavLink>
									</li>;
								})
								}
								</ul>
							</div>								
						</Col>
					</Row>
				  : null }
			  {this.props.activeMenu ? 
				<Row>
					<Col md={12} className="p-0" >									
						 <div className="de-left-tit">
							<h5 className="mmm-sidebar-menu-title" ><span className="ion-ios7-arrow-right" style={{
									color:'#fff',
									fontSize: '20px',
									fontWeight: '600',
									marginRight: '5px',
									}} ></span> {this.props.activeMenuTitle}</h5>
									<hr />
						</div>
						<div className="ho-event">
							<ul className="mmm-sidebar-menu" >
							{this.props.activeMenu.map((item,i)=>{
								return <li key={i} >
										<Link to={item.link}  >
											{item.name}
										</Link>
								</li>;
							})
							}
							</ul>
						</div>								
					</Col>
				</Row>
			  : null }
				<Row>
					<Col md={12} className="p-0" >									
						 <div className="de-left-tit">
							<h5 className="mmm-sidebar-menu-title" ><span className="ion-ios7-arrow-right" style={{
									color:'#fff',
									fontSize: '20px',
									fontWeight: '600',
									marginRight: '5px',
									}} ></span> ACADEMICS</h5>
									<hr />
						</div>
						<div className="ho-event">
							<ul className="mmm-sidebar-menu" >
							{DT_Academics.map((item,i)=>{
								return <li key={i} >
										<Link to={item.link}  >
											{item.name}
										</Link>
								</li>;
							})
							}
							</ul>
						</div>	
					
					</Col>
				</Row>
		</div>
						
		);
	}
	
}
export default Sidebar;
