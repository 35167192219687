import React, { Component } from 'react';
import {Redirect, Link, NavLink} from 'react-router-dom';
import $ from 'jquery';
import Cookies from 'universal-cookie';
import API from '../../../utils/API';
import {Api, DT_Programmes, DT_Syllabus, DT_Courseoutcomes} from '../../../utils/Variables';
import {getCookieField, capitalizeFirst, makeUrl, getAscSortOrder, removeBothSlash} from '../../../utils';

import {FbLoader} from '../../../elements';

import './DropdownMenu.css';

class DropdownMenu extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			menuList: [],
			htmlStrTxt: [],
			depthLevel: 1,
		}
	}
	
	getMenuItemTitle(menuItem, index, depthLevel){
		return menuItem.title;
	 }
	 
	 getMenuItemLink(menuItem, index, depthLevel){
		return menuItem.menulink;
	 }

	  getMenuItem(menuItem, depthLevel, index){
		let title = this.getMenuItemTitle(menuItem, index, depthLevel);
		let menulink = this.getMenuItemLink(menuItem, index, depthLevel);

		if (menuItem.children && menuItem.children.length > 0) {
		  return (
			<li>
			  <a href="javascript:;">{title}</a>
			  <DropdownMenu config={menuItem.children} depthLevel={depthLevel+1} submenu={true} />
			</li>
		  );
		} else {
		  return <li>
			<NavLink exact to={'/'+removeBothSlash(menulink)} >{title} </NavLink>
		  </li>;
		}
	  }

	  render(){
		let { config } = this.props;
		
		let options = [];
		config.map((item, index) => {
		  options.push(this.getMenuItem(item, 0, index));
		});

		if (this.props.submenu && this.props.submenu === true)
		  return <ul className="cl__dropdown-menu" >{options}</ul>;

		return <ul className="cl__dropdown-menu">{options}</ul>;
	  }
	
}
export default DropdownMenu;