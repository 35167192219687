import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import ANNOUNCE_IMG from "../../../assets/img/announce_speaker.png";
import API from "../../../utils/API";

class SidebarRight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      dataList: [],
    };
  }

  componentDidMount() {
    this.loadAnnounceMents();
  }

  loadAnnounceMents() {
    try {
      this.setState({ showLoader: true });
      var form = new FormData();
      form.append("type", "announcement");
      form.append("expired", "1");
      API.post("site/v1/news/list", form).then((res) => {
        if (res["data"].status == "1") {
          this.setState({ dataList: res["data"].data, showLoader: false });
        } else {
          this.setState({ showLoader: false });
        }
      });
    } catch (error) {
      this.setState({ showLoader: false });
    }
  }

  marqueeStop() {
    document.getElementById("mmm_an_scroll_mq").stop();
  }

  marqueeStart() {
    document.getElementById("mmm_an_scroll_mq").start();
  }

  render() {
    return (
      <div>
        <Row>
          <Col md={12}>
            <div className="de-left-tit">
              <h4 className="ps__announce_h4" style={{ fontSize: "17px" }}>
                <img
                  src={ANNOUNCE_IMG}
                  className="blink-image"
                  style={{ width: "45px", marginRight: "15px" }}
                />
                ANNOUNCEMENTS
              </h4>
              <hr />
            </div>
          </Col>
          <Col md={12}>
            <marquee
              id="mmm_an_scroll_mq"
              direction="up"
              scrollamount="4"
              style={{ height: "300px" }}
              onMouseOver={this.marqueeStop}
              onMouseLeave={this.marqueeStart}
            >
              <ul className="list-style-none" style={{ paddingLeft: "0px" }}>
                {this.state.dataList.map((item, i) => {
                  return (
                    <li key={i} style={{ listStyle: "none" }}>
                      {/*item.title
									<br />*/}
                      <div dangerouslySetInnerHTML={{ __html: item.content }} />
                    </li>
                  );
                })}
              </ul>
            </marquee>
          </Col>
        </Row>
      </div>
    );
  }
}
export default SidebarRight;
