import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card} from 'react-bootstrap';
import API from '../../../utils/API';
import {CircleSlowLoader} from '../../../elements';

import Page from '../Page';
import IMG_404 from '../../../assets/img/404.gif';
import PageHeader from '../Page/PageHeader';
import './index.css';
import WOW from 'wowjs';

class NotFound extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			pageUrl: '',
			pageData: [],
		}
	}
	
	componentDidMount(){
		new WOW.WOW().init();
		this.state.showLoader = true;
		this.state.pageUrl = this.props.match.params[0].replace(/^\/|\/$/g, '');
		
		this.loadPageContent();
	}
	
	componentWillReceiveProps(props){
		new WOW.WOW().init();
		this.state.pageUrl = props.match.params[0].replace(/^\/|\/$/g, '');
		
		this.loadPageContent();
		//this.forceUpdate();
	}
	
	loadPageContent(){
		try{
			
			this.setState({showLoader:true, pageData: []});
			API.get('site/v1/page/'+this.state.pageUrl).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({pageData: res['data'].data, showLoader:false});
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	
	render(){
		
		
		return(
		<div>
		
			<PageHeader />
			
		<div  className="container page-in pb-50 shadow-lg  ">
		
			{this.state.showLoader ? 
				<div className="container py-10 px-10 bg-white" >
					<Row>	
						<Col md={12} >
							<CircleSlowLoader /> 
						</Col>
					</Row>
				</div> : 
				
				Object.keys(this.state.pageData).length<=0 ?
					<div className="container py-10 px-10 bg-white" >
					{/* <Row>	
							<Col md={12} >
								<div className="text-center" >
								  <img src={IMG_404} alt="404"   className="img-fluid rounded-circle mb-3 img-thumbnail " width="550" />
								  <h1> Error 404 page not found</h1>
								  <p>The page you were looking for doesn't exist.</p>
								  <a href="javascript:history.back()"  className="btn btn-danger rounded-pill"><i data-feather="arrow-left"></i> Go back</a>
							  </div>
							</Col>
					</Row> */}
					<div id="notfound">
						<div className="notfound">
							<div>
								<div className="notfound-404">
									<h1>!</h1>
								</div>
								<h2>Error<br />404</h2>
							</div>
							<p>The page you are looking for might have been removed had its name changed or is temporarily unavailable. 
							{/*<a href="javascript:history.back()" >Back to homepage</a>*/}
							<Link to="/">Back to homepage</Link>
							</p>
						</div>
						</div>
					</div>
				: <Page pageData={this.state.pageData} />
				}

		</div>
		</div>
		);
	}
	
}
export default NotFound;
