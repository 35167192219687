import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/font-awesome/css/font-awesome.min.css";
import "./assets/ionicons/css/ionicons.min.css";

import "suneditor/dist/css/suneditor.min.css";
import "katex/dist/katex.min.css";
import "./assets/gellix/style.css";

//import './assets/css/animate.css';
import "./assets/css/custom.css";
import "./assets/style.css";

import App from "./App";

import "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));
