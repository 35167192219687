import React, { Component } from 'react';
import {Redirect, Link, NavLink} from 'react-router-dom';
import $ from 'jquery';
import {Navbar, Nav, NavDropdown} from 'react-bootstrap';
import Cookies from 'universal-cookie';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {getCookieField, capitalizeFirst, makeUrl, getls} from '../../../utils';

import TDMNS_LOGO from '../../../assets/img/tdmnsc-1.png';
import KAMARAJ_LOGO from '../../../assets/img/kamarajar-logo.png';

import TopMenu from './TopMenu';
import TopScroll from '../TopScroll';
import Slider from '../Slider';

class Header extends Component{
	constructor(props){
		super(props);
		this.state = {
			categoryList: [],
		}
	}
	
	componentDidMount(){
		
		window.addEventListener('scroll', this.handleWindowScroll, true);
		/*$('.has-dropdown').mouseenter(function(){
			var $this = $(this);
			$this
				.find('.dropdown')
				.css('display', 'block')
				.addClass('animated-fast fadeInUpMenu');

		}).mouseleave(function(){
			var $this = $(this);
			$this
				.find('.dropdown')
				.css('display', 'none')
				.removeClass('animated-fast fadeInUpMenu');
		})*/
				
	}
	
	
	handleWindowScroll(){
		if ($("#mainNav").offset().top > 100) {
		  $("#mainNav").addClass("navbar-scrolled");
		  $(".top-announcement").hide();
		} else {
		  $("#mainNav").removeClass("navbar-scrolled");
		  $(".top-announcement").show();
		}
	}
	
	
	render(){

		$('html, body').animate({scrollTop:0}, 'slow');
		
		return(			
			<div className="" >
				 <div className="container">
					  <div className="row">
					  <h3 className="top-title">Tirunelveli Dakshina Mara Nadar Sangam College, T.Kallikulam</h3>
					  {/*T.Kallikulam - 627113 <br />*/}
					  <p className="top-title2" style={{top: '48px'}} >(Co-Education Institution.Estb.1971)(Accredited by NAAC with B+ Grade)</p>
					  <p className="top-title2" style={{top: '68px'}}  >Affiliated to Manonmaniam Sundaranar University, Tirunelveli</p>
				  </div>
				  </div>
				  
				  <nav className="navbar navbar-expand-lg navbar-light fixed-top py-3" id="mainNav">

				  <div className="clear-fix"></div>
					<div className="container">
					  <Link className="navbar-brand js-scroll-trigger" to="/">
						<img src={TDMNS_LOGO}  className="logo-imgs"/> 
						</Link>
					  <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
					   MENU <span className="navbar-toggler-icon"></span>
					  </button>
					  
					  <TopMenu />
					  
					  <img src={KAMARAJ_LOGO} className="logo-imgs" />
					</div>
				  </nav>
				  
			</div>
		);
	}
	
}
export default Header;