const COOKIE_KEY = "cb_ecom";

const Api = {
  /*Url :'http://localhost/kalvisalai/stc/php/api/',
	ImageUrl: 'http://localhost/kalvisalai/stc/php/',
	AcademicPath: 'https://admin.sarahtuckercollege.edu.in/api/'*/

  Url: "https://webadmin.tdmnscollege.edu.in/services/api/",
  ImageUrl: "https://webadmin.tdmnscollege.edu.in/services/",
  AcademicPath: "https://webadmin.tdmnscollege.edu.in/academicapi/",
};

const Site = {
  Title: "TIRUNELVELI DAKSHANA MARA NADAR SANGAM COLLEGE - T.KALLIKULAM",
};

const DT_Academics = [
  { name: "Programmes", link: "/programmes/ug" },
  { name: "Syllabus", link: "/syllabus/ug" },
  { name: "Course Outcomes", link: "/pso-and-course-outcomes/ug" },
  { name: "Teaching Plan", link: "/teaching-plan" },
];

/* const DT_Programmes = [
	{name: 'UG Programmes', link :'/programmes/ug'},
	{name: 'PG Programmes', link :'/programmes/pg'},
	{name: 'M.Phil Programmes', link :'/programmes/mphil'},
	{name: 'Ph.D Programmes', link :'/programmes/phd'},
]; */

const DT_Programmes = [
  { name: "Aided Programmes", link: "/programmes/aided" },
  { name: "Self Finance Programmes", link: "/programmes/unaided" },
];

const DT_Syllabus = [
  { name: "UG Syllabus", link: "/syllabus/ug" },
  { name: "PG Syllabus", link: "/syllabus/pg" },
  { name: "M.Phil Syllabus", link: "/syllabus/mphil" },
];

const DT_Courseoutcomes = [
  { name: "UG Course Outcomes", link: "/pso-and-course-outcomes/ug" },
  { name: "PG Course Outcomes", link: "/pso-and-course-outcomes/pg" },
  { name: "M.Phil Course Outcomes", link: "/pso-and-course-outcomes/mphil" },
];

const DT_CourseMenu = [
  { name: "About", link: "about" },
  { name: "Vision & Mission", link: "vision" },
  { name: "Courses Offered", link: "courses" },
  { name: "Faculty", link: "faculty" },
  { name: "Former Faculty", link: "former_faculty" },
  { name: "Syllabus", link: "syllabus" },
  { name: "PSO & CO", link: "courseoutcomes" },
  //{name: 'POS & Teaching Plan', link : 'teachingplan'},
  { name: "E-Material", link: "ematerial" },
  { name: "Awards", link: "awards" },
  { name: "Alumni", link: "alumni" },
];

const DT_QuickLinks = [
  { name: "Grievances", link: "/grievances" },
  { name: "Mandatory Disclosure", link: "mandatory-disclosure" },
  { name: "Anti Ragging", link: "anti-ragging" },
  { name: "Anti – Harassment", link: "anti-harassment" },
  { name: "Academic Calendar", link: "academic-calendar" },
  { name: "Fee Structure", link: "fee-structure" },
  { name: "Clubs & Cells", link: "clubs-cells" },
  { name: "Scholarships", link: "scholarships" },
  { name: "NIRF", link: "nirf" },
  { name: "NAAC", link: "naac" },
  { name: "Hostel", link: "hostel" },
];

export {
  COOKIE_KEY,
  Api,
  Site,
  DT_Academics,
  DT_Programmes,
  DT_Syllabus,
  DT_Courseoutcomes,
  DT_CourseMenu,
  DT_QuickLinks,
};
