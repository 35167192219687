import React, { Component } from 'react';
import {Redirect, Link, NavLink} from 'react-router-dom';
import $ from 'jquery';
import Cookies from 'universal-cookie';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {getCookieField, capitalizeFirst, makeUrl, getls} from '../../../utils';

class FloatLeftButton extends Component{
	constructor(props){
		super(props);
		this.state = {
			categoryList: [],
		}
	}
		
	
	render(){
		
		return(
			<div id="cs-mySidenav" className="sidenav">
			  <a href="" id="about">
				<span><i className="fa fa-sign-in"></i></span>&nbsp;&nbsp;
				Student Portal</a>
			  <a href="https://alumni.sriparasakthicollege.edu.in/" target="_blank" id="blog">
				<span><i className="fa fa-users"></i></span>&nbsp;&nbsp;
				Alumni</a>
			  <a href="" id="projects">
				<span><i className="fa fa-book"></i></span>&nbsp;&nbsp;
				Exam Results</a>
			  <a href="" id="contact">
				<span><i className="fa fa-phone"></i></span>&nbsp;&nbsp;
			  Feedback</a>
			</div>
		);
	}
	
}
export default FloatLeftButton;