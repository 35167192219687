import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import $ from "jquery";
import { Row, Col, Card } from "react-bootstrap";
import { Api, DT_Academics, DT_Programmes } from "../../../utils/Variables";
import {
  upperCase,
  groupByMultiple,
  makeUrl,
  getAscSortOrder,
} from "../../../utils";
import API from "../../../utils/API";
import { CircleSlowLoader } from "../../../elements";
import axios from "axios";
import NOIMAGE from "../../../assets/img/noimage-placeholder.png";

import PageHeader from "../Page/PageHeader";
import Sidebar from "./Sidebar";
import SidebarRight from "./SidebarRight";

class ProgrammesByType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courseList: [],
      courseView: [],
      courseTypeGroup: [],
      batchList: [],
      courseType: this.props.match.params.ptype,
    };
  }

  componentDidMount() {
    this.state.courseType = this.props.match.params.ptype;
    //this.loadBatchAndCourse();
    this.loadCourseByType();
  }

  componentWillReceiveProps(props) {
    try {
      this.state.courseType = props.match.params.ptype;
      this.state.activeTab = "-1";
      //this.forceUpdate();
      this.loadCourseByType();
      //this.showDataByCourseType();
    } catch (error) {
      console.log(error);
    }
  }

  loadCourseByType() {
    try {
      this.setState({
        showLoader: true,
        batchList: [],
        courseList: [],
        courseView: [],
      });
      //axios.post(Api.AcademicPath+'course/coursewithtype/').then(res=>{
      var c = this.state.courseType == "aided" ? "aided" : "un-aided";
      API.get("site/v1/course/coursebytype/" + c).then((res) => {
        if (res["data"].status == "1") {
          this.setState({
            courseList: res["data"].data,
            courseView: res["data"].data,
            showLoader: false,
          });
        } else {
          this.setState({ showLoader: false });
        }
      });
    } catch (error) {
      this.setState({ showLoader: false });
    }
  }

  loadBatchAndCourse() {
    try {
      this.setState({
        showLoader: true,
        batchList: [],
        courseList: [],
        courseView: [],
      });
      //axios.post(Api.AcademicPath+'course/coursewithtype/').then(res=>{
      API.get("site/v1/coursewithtype/").then((res) => {
        if (res["data"].status == "1") {
          var d = this.courseOrder(res["data"].data);
          this.setState({
            batchList: res["data"].batch,
            courseList: d,
            showLoader: false,
          });

          this.showDataByCourseType();
        } else {
          this.setState({ showLoader: false });
        }
      });
    } catch (error) {
      console.log(error);
      this.setState({ showLoader: false });
    }
  }

  courseOrder(course) {
    try {
      var rv = [];

      var corder = [
        { type: "UG", coursetype: "aided" },
        { type: "UG", coursetype: "un-aided" },
        { type: "PG", coursetype: "aided" },
        { type: "PG", coursetype: "un-aided" },
        { type: "MPhil", coursetype: "aided" },
        { type: "MPhil", coursetype: "un-aided" },
        { type: "PhD", coursetype: "aided" },
        { type: "PhD", coursetype: "un-aided" },
      ];

      corder.map((item, i) => {
        var d = course.filter(function (obj) {
          return obj.type == item.type && obj.coursetype == item.coursetype;
        });
        //rv.push(d);
        d.map((m, k) => {
          rv.push(m);
        });
      });

      return rv;
    } catch (error) {}
  }

  showDataByCourseType() {
    try {
      var type = this.state.courseType;

      this.setState({ courseTypeGroup: [] });
      var d = [];
      if (type == "mphil") {
        d = this.state.courseList.filter(function (obj) {
          return upperCase(obj.coursename) == upperCase("MPhil");
        });
      } else if (type == "phd") {
        d = this.state.courseList.filter(function (obj) {
          return upperCase(obj.coursename) == upperCase("Phd");
        });
      } else {
        d = this.state.courseList.filter(function (obj) {
          return upperCase(obj.type) == upperCase(type);
        });
      }

      var s = groupByMultiple(d, function (obj) {
        return [obj.coursetype];
      });

      if (Object.keys(s).length >= 2) {
        s[0].sort(getAscSortOrder("deptname"));
        s[1].sort(getAscSortOrder("deptname"));
      } else if (Object.keys(s).length >= 1) {
        s[0].sort(getAscSortOrder("deptname"));
      }

      this.setState({ courseView: d, courseTypeGroup: s });
    } catch (error) {
      this.setState({ showLoader: false });
    }
  }

  getImagePath(path) {
    try {
      if (path == "" || path == null || path == undefined || path.length < 1) {
        return NOIMAGE;
      } else {
        return Api.ImageUrl + path;
      }
    } catch (error) {
      return NOIMAGE;
    }
  }

  render() {
    return (
      <section>
        <PageHeader />

        <div className="container page-in pb-50 pt-30 shadow-lg  ">
          <Row>
            <Col md={3}>
              <Sidebar
                activeMenu={DT_Programmes}
                activeMenuTitle="PROGRAMMES"
              />
            </Col>

            <Col md={6}>
              {this.state.showLoader ? (
                <CircleSlowLoader />
              ) : (
                <div>
                  <div className="con-title text-center">
                    <h5>
                      {upperCase(this.state.courseType)} <span>Programmes</span>
                    </h5>
                    <hr />
                  </div>

                  <Row>
                    {this.state.courseList.map((item, index) => {
                      return (
                        <Col md={4} key={index} className="mb-10">
                          <Link
                            to={
                              "/department/" +
                              item.id +
                              "/" +
                              makeUrl(item.departmentname)
                            }
                          >
                            <div className="card">
                              <div className="card-body  text-center">
                                <img
                                  src={this.getImagePath(item.logopath)}
                                  style={{ width: "80%" }}
                                />
                                <hr className="mb-0" />
                                <h5 className="text-center py-10">
                                  {item.departmentname}
                                </h5>
                              </div>
                            </div>
                          </Link>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              )}
            </Col>

            <Col md={3}>
              <SidebarRight
                activeMenu={DT_Programmes}
                activeMenuTitle="PROGRAMMES"
              />
            </Col>
          </Row>
        </div>
      </section>
    );
  }
}
export default ProgrammesByType;
