import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import $ from "jquery";
import { Carousel } from "react-bootstrap";
import API from "../../../utils/API";
import { Api } from "../../../utils/Variables";
import { CircleLoader } from "../../../elements";

import SliderItem from "./SliderItem";
import SliderAnnouncement from "../TopScroll/SliderAnnouncement";

const styles = {
  placeHolder: {
    height: "calc(100vh)",
    paddingTop: "20%",
    background: "linear-gradient(180deg, #074a4ce0, #18b2cc)",
  },
};

class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
      dataList: [],
      activeSlide: 0,
    };
  }

  componentDidMount() {
    this.loadSliders();
  }

  loadSliders() {
    try {
      this.setState({ showLoader: true });
      API.get("site/v1/sliders/").then((res) => {
        if (res["data"].status == "1") {
          this.setState({ dataList: res["data"].data, showLoader: false });
        } else {
          this.setState({ showLoader: false });
        }
      });
    } catch (error) {
      this.setState({ showLoader: false });
    }
  }

  render() {
    const Items = [
      {
        src: "http://sarahtuckercollege.edu.in/web/wp-content/uploads/2017/12/bg-5.jpg",
        caption: "Slider I",
        header: "",
        altText: "",
        key: "1",
      },
      {
        src: "http://sarahtuckercollege.edu.in/web/wp-content/uploads/2018/04/bg-6.jpg",
        caption: "Slider II",
        header: "",
        altText: "",
        key: "2",
      },
      {
        src: "http://sarahtuckercollege.edu.in/web/wp-content/uploads/2017/12/bg33.jpg",
        caption: "Slider III",
        header: "",
        altText: "",
        key: "3",
      },
    ];

    if (this.state.showLoader) {
      return (
        <div style={styles.placeHolder}>
          <center>
            <CircleLoader />
          </center>
        </div>
      );
    }

    return (
      <header className="masthead">
        {/*<div className="slider-top-placeHolder" ></div>*/}
        <div className="h-100">
          <div className=" h-100 align-items-center justify-content-center text-center">
            <Carousel>
              {this.state.dataList.map((item, i) => {
                return (
                  <Carousel.Item>
                    <img
                      src={Api.ImageUrl + item.url}
                      alt=""
                      style={{ maxHeight: "500px" }}
                      className="w-100"
                    />
                    {item.title.length > 0 ? (
                      <Carousel.Caption>
                        <h3>{item.title}</h3>
                      </Carousel.Caption>
                    ) : null}
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </div>
        </div>

        {/*<SliderAnnouncement />*/}
      </header>
    );
  }
}
export default Slider;
