import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Table} from 'react-bootstrap';
import {CircleSlowLoader} from '../../../../elements';
import ReactModal from 'react-modal';
import FacultyRow from './FacultyRow';
import {Api, Site} from '../../../../utils/Variables';

class Faculty extends Component{
	constructor(props){
		super(props);
		this.state = {
			showModal: false,
			fileUrl: '',
		}
		
		this.showFile = this.showFile.bind(this);
	}
	
	showFile(file){
		try{
			
			if(file) this.setState({fileUrl: Api.ImageUrl+file, showModal: true});
		}
		catch(error){ }
	}
	
	render(){
	
		var emplist = this.props.facultyList;
		if(emplist==undefined) emplist = [];
		let sno =1;
		
		return(
			 <div>
				<table className="table table-bordered" >
					<thead style={{backgroundColor: '#e4e4e4'}} >
						<tr>
							<th>S.No</th>
							<th>Name of the Faculty</th>
							<th>Photo</th>
						</tr>
					</thead>
					<tbody>
						{emplist.map((item,i)=>{
							return <FacultyRow data={item} sno={sno++} fileClick={this.showFile}  />;
						})
						}
					</tbody>
				</table>
				
				<ReactModal isOpen={this.state.showModal} >
					<div className="pdf__modal__header" >
						<div className="text-left" >
							<h4>{Site.Title}</h4>
						</div>
						<div className="text-right" style={{marginTop: '-35px'}} >
							<button type="button" onClick={(e)=>{this.setState({showModal: false, fileUrl: ''})}} className="btn btn-danger" >Close</button>
						</div>
						<hr style={{marginTop: '5px', marginBottom: '0px'}} />
					</div>
					
					<object data={this.state.fileUrl} type="application/pdf" width="100%" height="850">
						<p>
							It appears your Web browser is not configured to display PDF files. No worries, just <a href="your_file.pdf">click here to download the PDF file.</a>
						</p>
					</object>
				</ReactModal>
		
			</div>
		);
	}
	
}
export default Faculty;
