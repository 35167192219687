import React from "react";
import { Card, Col, Row } from "react-bootstrap";

const OurRecruiter = (props) => {
  const alumniData = [
    "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/rec/rec5.jpg",
    "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/rec/rec7.jpg",
    "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/rec/rec3.jpg",
    "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/rec/rec4.jpg",
    "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/rec/rec2.jpg",
    "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/rec/rec1.jpg",
    "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/rec/rec6.jpg",
  ];

  const getCard = (item) => {
    return (
      <li>
        <div className=" home-top-cour " style={{ width: "320px" }}>
          <img src={item} className="w-100" />
        </div>
      </li>
    );
  };

  return (
    <>
      <section>
        <div className="container mb-4 ps__rec">
          <h2 className="text-center py-4">Our Recruiters</h2>

          <marquee>
            <ul className="">{alumniData.map((item) => getCard(item))}</ul>
          </marquee>
        </div>
      </section>
    </>
  );
};

export default OurRecruiter;
