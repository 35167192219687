import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const Achivers = (props) => {
  const [windowSize, setWindowSize] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      // Perform actions on window resize
      //setWindowSize([window.innerWidth, window.innerHeight]);
      setWindowSize(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setWindowSize(window.innerWidth);
  }, []);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: windowSize && windowSize < 500 ? 1 : 3,
    slidesToScroll: windowSize && windowSize < 500 ? 1 : 3,
    autoplay: true,
  };

  const alumniData = [
    {
      name: "S. Sahaya Melviya",
      department: "B.A., Tamil",
      rank: "Part III (Major) 11th Rank",
      img: "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/rankholders/1.jpg",
    },
    {
      name: "S. Sathya Pavithra",
      department: "B.A., Tamil",
      rank: "Part III (Major) 8th Rank",
      img: "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/rankholders/2.jpg",
    },
    {
      name: "M. Sakthi",
      department: "B.Sc., Physics",
      rank: "Part III (Major) 7th Rank",
      img: "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/rankholders/3.jpg",
    },
    {
      name: "P. Thanga Priya",
      department: "B.Sc., Physics",
      rank: "Part II (English) 8th Rank",
      img: "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/rankholders/4.jpg",
    },
    {
      name: "R. Jebastin Abina",
      department: "B.Sc., Mathematics",
      rank: "Part I (Tamil) 12th Rank",
      img: "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/rankholders/5.jpg",
    },
    {
      name: "N. Murugammal",
      department: "B.Sc., Mathematics",
      rank: "Part I (Tamil) 8th Rank",
      img: "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/rankholders/6.jpg",
    },
    {
      name: "N. Esakkimuthu",
      department: "M.Sc., Mathematics",
      rank: "Part III (Major) 7th Rank",
      img: "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/rankholders/7.jpg",
    },
    {
      name: "D. Keerthika Snowlin",
      department: "M.Com.,",
      rank: "Part III (Major) 4th Rank",
      img: "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/rankholders/8.jpg",
    },
    {
      name: "S. Epsi",
      department: "B.A., History",
      rank: "Part III (Major) 16th Rank",
      img: "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/rankholders/9.jpg",
    },
    {
      name: "V. Varshini",
      department: "B.A., English",
      rank: "Part II (English) 11th Rank & Part III (Major) 7th Rank",
      img: "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/rankholders/10.jpg",
    },
    {
      name: "K. Jenish",
      department: "B.A., Economics",
      rank: "Part III (Major) 11th Rank",
      img: "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/rankholders/11.jpg",
    },
    {
      name: "S. Esakkiammal",
      department: "B.A., Economics",
      rank: "Part III (Major) 16th Rank",
      img: "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/rankholders/12.jpg",
    },
    {
      name: "S. Velammal",
      department: "B.Sc., Chemistry",
      rank: "Part I (Tamil) 8th Rank",
      img: "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/rankholders/13.jpg",
    },
    {
      name: "V. Gayathri",
      department: "B.Sc., Botany",
      rank: "Part III (Major) 18th Rank",
      img: "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/rankholders/14.jpg",
    },
    {
      name: "A. Sasi Bharathi",
      department: "B.Sc., Botany",
      rank: "Part III (Major) 15th Rank",
      img: "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/rankholders/15.jpg",
    },
    {
      name: "J. Helvin Melancy",
      department: "B.Sc., Botany",
      rank: "Part III (Major) 11th Rank",
      img: "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/rankholders/16.jpg",
    },
    {
      name: "E. Susila",
      department: "B.Sc., Botany",
      rank: "Part I (Tamil) 10th Rank",
      img: "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/rankholders/17.jpg",
    },
    {
      name: "N. Sunitha",
      department: "B.Sc., Botany",
      rank: "Part III (Major) 16th Rank",
      img: "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/rankholders/18.jpg",
    },
  ];

  const getCard = (item, k) => {
    return (
      <div className="px-3 home-top-cour ps__alumni">
        <Card className="">
          <Card.Img src={item.img} />
          <Card.Body className={k % 2 == 0 ? "color-light-1" : "color-light-2"}>
            <Row className="">
              <Col md={12}>
                <div
                  style={{
                    height: "75px",
                    overflowY: "auto",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  <h4 style={{ fontSize: "17px" }}>{item.name}</h4>
                  <div>{item.department}</div>
                  <div>{item.rank}</div>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    );
  };

  return (
    <>
      <section
        className="page-section pt-30 pb-30"
        style={{ backgroundColor: "rgb(214, 237, 209)" }}
      >
        <div className="container mb-4">
          <h2 className="text-center py-4">Our Achievers</h2>
          <Slider {...settings}>
            {alumniData.map((item, i) => getCard(item, i))}
          </Slider>
        </div>
      </section>
    </>
  );
};

export default Achivers;
