import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter , Switch, Route, Link, HashRouter, IndexRoute, Redirect } from 'react-router-dom';
import Loader from '../views/Loader';
import SiteLayout from '../layouts/SiteLayout';


export default () => (
	
<BrowserRouter basename="/" >
	<Suspense fallback={<div><Loader /></div>} >
		<Switch>
			<Route path="" exact component={SiteLayout} />
		</Switch>
	</Suspense>
</BrowserRouter >
);