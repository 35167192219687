import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import $ from "jquery";
import { Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import API from "../../../utils/API";
import { Api } from "../../../utils/Variables";
import { getCookieField } from "../../../utils";

import MarqueeAnnouncement from "./MarqueeAnnouncement";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <section className="page-section bg-primary py-40" id="about">
        <div className="container">
          <div className="row justify-content-center">
            <div
              className="col-lg-8 text-justify"
              style={{ lineHeight: "28px" }}
            >
              <h4 style={{ lineHeight: "44px", color: "white" }}>
                <small>Welcome to</small> <br />
                <big>Tirunelveli Dakshina Mara Nadar Sangam College</big>
              </h4>
              The Tirunelveli Dakshina Mara Nadar Sangam, Sindupoondurai.
              Tirunelveli, ever since its inception has had the promotion of
              education as one of its main objectives.For many years it had been
              helping poor students with loan scholarships. Further, it had been
              giving donations to certain private colleges in Tirunelveli
              District.Then,the Sangam decided to open a College of its own and
              true to its principles of rendering service where it is most
              needed,the Sangam chose to start a College in the erstwhile
              Nanguneri Taluk, which had till then the unique distinction of
              being one of the Taluks in the District without a College. The
              outcome is the Tirunelveli Dakshina Mara Nadar Sangam College at
              T. Kallikulam
              <br />
              <br />
              <Link
                className="btn btn-danger btn-xl js-scroll-trigger"
                to="/college"
              >
                About College
              </Link>
              <a className="btn btn-info btn-xl js-scroll-trigger" href="#">
                Admission
              </a>
              <Link
                className="btn btn-success btn-xl js-scroll-trigger"
                to="/contact"
              >
                Contact Us
              </Link>
            </div>
            <div className="col-lg-4">
              <h3 style={{ color: "white", textAlign: "center" }}>
                News & Events
              </h3>

              <div
                style={{ background: "transparent", padding: "15px" }}
                className="shadow-lg cs-announcement-top"
              >
                <MarqueeAnnouncement />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default About;
