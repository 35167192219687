import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {Slider, CategoryWidget, FeaturedProduct, ProductCollection, LineLoader} from '../../elements';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {getCookieField} from '../../../utils';

import MarqueeAnnouncement from './MarqueeAnnouncement';

import BG_VISION from '../../../assets/img/bg-vision.jpg';

import IMG_CALENDAR from '../../../assets/img/quick-links/q-calendar.png';
import IMG_REPORT from '../../../assets/img/quick-links/q-report.png';
import IMG_MAGAZINE from '../../../assets/img/quick-links/q-magazine.png';
import IMG_IEW from '../../../assets/img/quick-links/q-iew.png';
import IMG_PRACTICE from '../../../assets/img/quick-links/q-practice.png';
import IMG_NEWSLETTER from '../../../assets/img/quick-links/q-newsletter.png';
import IMG_FEEDBACK from '../../../assets/img/quick-links/q-feedbck.png';
import IMG_JOURNAL from '../../../assets/img/quick-links/q-journal.png';


const styles = {
	visionCss: {
		borderRadius: '5px',
		backgroundImage: 'linear-gradient(rgb(226, 38, 226), rgb(82, 17, 82))',
		color: '#fff',
		backgroundSize: '100% 100%',
		//fontFamily: 'Great Vibes, cursive',
		padding: '20px',
		marginTop: '20px',
	},
	visionText: {
		padding: '10px',
		fontSize: '16px',
		lineHeight: '25px',
		textAlign: 'center',
	},
	missionCss: {
		borderRadius: '5px',
		textAlign: 'center',
		backgroundImage: 'linear-gradient(rgb(226, 38, 226), rgb(82, 17, 82))',
		color: '#fff',
		//fontFamily: 'Great Vibes, cursive',
		backgroundSize: '100% 100%',
		padding: '20px',
	},
	announceCss: {
		padding: '15px',
		borderRadius: '5px',
		backgroundImage: 'linear-gradient(rgb(226, 38, 226), rgb(82, 17, 82))',
		color: '#fff',
		backgroundSize: '100% 100%',
		backgroundRepeat: 'no-repeat',
		//fontFamily: 'Great Vibes, cursive',
		padding: '20px',
	},
	headingCss:{
		fontFamily: 'Aladin, fantasy',
		fontSize: '26px',
		padding: '0px',
		margin: '0px',
		color: '#fffa4e',
		paddingTop: '15px',
	}
}

class QuickLinks extends Component{
	constructor(props){
		super(props);
		this.state = {
			activeTab: 'vision',
		}
	}
	
	componentDidMount(){
	
	}
	
	
	
	render(){
		
		
		return(
			<section className="page-section pb-60 pt-40 bg-light home-quick-links"  >
				{/*<section className="page-section py-60"  style={{backgroundImage: 'linear-gradient(to top, rgb(121, 19, 121), rgb(253, 198, 253))'}} >*/}
				<div className="container" >
					<Row>
						<Col md={12} >
							<h3 className="text-center wow slideInDown" >Quick Links</h3>
							<hr className="type_4" />
						</Col>
						<Col md={3} className="mob-50_1 wow slideInDown" >
							<Link to="/academic-calendar" >
								<div className="home-top-cour shadow-lg text-center" style={styles.visionCss} >
									<div style={styles.visionText} >
										<img src={IMG_CALENDAR} />
									</div>
									<h4 className="text-center" style={styles.headingCss}  >Academic calendar</h4>
								</div>
							</Link>
						</Col>
						
						<Col md={3} className="mob-50_1 wow slideInDown" >
							<Link to="/annual-reports" >
								<div className="home-top-cour shadow-lg text-center" style={styles.visionCss} >
									<div style={styles.visionText} >
										<img src={IMG_REPORT} />
									</div>
									<h4 className="text-center" style={styles.headingCss}  >Annual Report </h4>
								</div>
							</Link>
						</Col>
						
						<Col md={3} className="mob-50_1 wow slideInDown" >
							<Link to="/" >
								<div className="home-top-cour shadow-lg text-center" style={styles.visionCss} >
									<div style={styles.visionText} >
										<img src={IMG_MAGAZINE} />
									</div>
									<h4 className="text-center" style={styles.headingCss}  >College Magazine</h4>
								</div>
							</Link>
						</Col>
						
						<Col md={3} className="mob-50_1 wow slideInDown" >
							<Link to="/iew" >
								<div className="home-top-cour shadow-lg text-center" style={styles.visionCss} >
									<div style={styles.visionText} >
										<img src={IMG_IEW} />
									</div>
									<h4 className="text-center" style={styles.headingCss}  >iew</h4>
								</div>
							</Link>
						</Col>
						
						<Col md={3} className="mob-50_1 wow slideInUp" >
							<Link to="/best-practices" >
								<div className="home-top-cour shadow-lg text-center" style={styles.visionCss} >
									<div style={styles.visionText} >
										<img src={IMG_PRACTICE} />
									</div>
									<h4 className="text-center" style={styles.headingCss}  >Best Practices</h4>
								</div>
							</Link>
						</Col>
						
						<Col md={3} className="mob-50_1 wow slideInUp" >
							<Link to="/iqac-newsletter" >
								<div className="home-top-cour shadow-lg text-center" style={styles.visionCss} >
									<div style={styles.visionText} >
										<img src={IMG_NEWSLETTER} />
									</div>
									<h4 className="text-center" style={styles.headingCss}  >IQAC Newsletter</h4>
								</div>
							</Link>
						</Col>
						
						<Col md={3} className="mob-50_1 wow slideInUp" >
							<Link to="/iqac-feedback" >
								<div className="home-top-cour shadow-lg text-center" style={styles.visionCss} >
									<div style={styles.visionText} >
										<img src={IMG_FEEDBACK} />
									</div>
									<h4 className="text-center" style={styles.headingCss}  >Feedback</h4>
								</div>
							</Link>
						</Col>
						
						<Col md={3} className="mob-50_1 wow slideInUp" >
							<Link to="/" >
								<div className="home-top-cour shadow-lg text-center" style={styles.visionCss} >
									<div style={styles.visionText} >
										<img src={IMG_JOURNAL} />
									</div>
									<h4 className="text-center" style={styles.headingCss}  >Journal</h4>
								</div>
							</Link>
						</Col>
						
						
					</Row>
				</div>
			</section>
		);
	}
	
}
export default QuickLinks;
