import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Carousel} from 'react-bootstrap';
import API from '../../../utils/API';

class SliderAnnouncement extends Component{
	constructor(props){
		super(props);
		this.state = {
			dataList: [],
		}
	}
	
	componentDidMount(){
		
		this.loadAnnounceMents();
	}
	
	
	handleWindowScroll(){
		window.addEventListener('scroll', this.handleWindowScroll, true);
		if ($("#mainNav").offset().top > 100) {
		  $(".top-announcement").hide();
		} else {
		  $(".top-announcement").show();
		}
	}
	
	loadAnnounceMents(){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('type','announcement');
			form.append('expired','1');
			API.post('site/v1/news/list',form).then(res=>{
				if(res['data'].status=='1'){
					this.setState({dataList: res['data'].data, showLoader: false});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	

	marqueeStop(){
		document.getElementById('mmm_an_scroll_mq').stop();
	}
	
	marqueeStart(){
		
		document.getElementById('mmm_an_scroll_mq').start();
	}
	
	showLink(item){
		try{
			if(item.link!='' && item.link!=null && item.link.length>2){
				return item.link;
			}
			else{
				return 'javascript:;';
			}
		}
		catch(error){
			return 'javascript:;';
		}
	}
	
	render(){
		
		
		return(			
			<div className="top-announcement shadow-lg shadow-hover-xl" >
					<div className="ta-header" >
						NEWS
					</div>
					<marquee direction="left" id="mmm_an_scroll_mq" onMouseOver={this.marqueeStop} onMouseLeave={this.marqueeStart} scrolldelay="150" style={{ marginTop: '-28px'}} className="announce">
						{this.state.dataList.map((item,i)=>{
							return item.title.length > 2 ? <a href={this.showLink(item)} >
								{item.title}
							</a> : null;
						})
						}
					</marquee>
			</div>
					
		);
	}
	
}
export default SliderAnnouncement;