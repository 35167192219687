import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import $ from "jquery";
import { Carousel, Row, Col } from "react-bootstrap";
import API from "../../../utils/API";
import { Api } from "../../../utils/Variables";
import { CircleSlowLoader } from "../../../elements";
import Gallery from "react-grid-gallery";

import SliderItem from "./SliderItem";
import Events from "./Events";

class NewsScroll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataList: [],
      showNewsLoader: false,
      upcomingList: [],
      galleryList: [],
    };
  }

  componentDidMount() {
    this.loadUpcomingNews();
    this.loadGalleryList();
  }

  loadGalleryList() {
    try {
      this.setState({ showGalleryLoader: true });
      API.get("site/v1/gallery/list/12").then((res) => {
        //console.log(res);
        if (res["data"].status == "1") {
          this.setState({
            galleryList: res["data"].data,
            showGalleryLoader: false,
          });
        } else {
          this.setState({ showGalleryLoader: false });
        }
      });
    } catch (error) {
      this.setState({ showGalleryLoader: false });
    }
  }

  loadUpcomingNews() {
    try {
      this.setState({ showNewsLoader: true });
      var data = new FormData();
      data.append("type", "upcoming");
      data.append("limit", "4");
      API.post("site/v1/news/list", data).then((res) => {
        //console.log(res);
        if (res["data"].status == "1") {
          this.setState({
            upcomingList: res["data"].data,
            showNewsLoader: false,
          });
        } else {
          this.setState({ showNewsLoader: false });
        }
      });
    } catch (error) {
      this.setState({ showNewsLoader: false });
    }
  }

  getImageList() {
    try {
      var rv = [];
      this.state.galleryList.map((item, i) => {
        rv.push({
          src: Api.ImageUrl + item.filepath,
          thumbnail: Api.ImageUrl + item.filepath,
          thumbnailWidth: 130,
          thumbnailHeight: 90,
        });
      });
      return rv;
    } catch (error) {
      return [];
    }
  }

  render() {
    return (
      <section className="bg-white pb-60">
        <div className="container">
          <div className="row animate-box">
            <div className="col-md-12 text-center fh5co-heading mt-40">
              <h3 className="text-center wow slideInDown">GALLERY</h3>
              <hr className="type_4" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-5 wow slideInLeft">
              <h4>Photo Gallery</h4>
              <div className="home_gallery ">
                {this.state.showGalleryLoader ? (
                  <CircleSlowLoader />
                ) : (
                  <Gallery images={this.getImageList()} />
                )}
              </div>
              <p
                style={{
                  position: "absolute",
                  bottom: "0px",
                  textAlign: "center",
                  width: "85%",
                }}
              >
                <center>
                  <Link to="/gallery" className="btn btn-primary btn-sm">
                    View More
                  </Link>
                </center>
              </p>
            </div>
            <div className="col-md-7">
              <div className="wow slideInRight">
                <h4>Video Gallery</h4>
                <Row>
                  {/*<Col md={6}>
								<iframe src="https://www.youtube.com/embed/D-cyh57LpNU?autoplay=0&amp;showinfo=0&amp;controls=0" allowfullscreen></iframe>
								<p style={{fontSize: '12px'}} >NTERNATIONAL YOGA DAY 2020</p>
								</Col>*/}
                  <Col md={6}>
                    <iframe
                      src="https://www.youtube.com/embed/zv9Wj1Ea3gc?autoplay=0&amp;showinfo=0&amp;controls=0"
                      allowfullscreen
                    ></iframe>
                    <p style={{ fontSize: "12px" }}>
                      சங்க இலக்கியமே அறிவியலாக..
                    </p>
                  </Col>
                  <Col md={6}>
                    <iframe
                      src="https://www.youtube.com/embed/s5QL133yrYA?autoplay=0&amp;showinfo=0&amp;controls=0"
                      allowfullscreen
                    ></iframe>
                    <p style={{ fontSize: "12px" }}>
                      பண்டைய தமிழர்களின் வாழ்வியலை கண்முன் கொண்டு வந்த மாணவர்கள்
                    </p>
                  </Col>
                  <Col md={6}>
                    <iframe
                      src="https://www.youtube.com/embed/Gxi6zCIajTQ?autoplay=0&amp;showinfo=0&amp;controls=0"
                      allowfullscreen
                    ></iframe>
                    <p style={{ fontSize: "12px" }}>
                      T.D.M.N.S COLLEGE PG CHEMISTRY ONE DAY NATIONAL LEVEL
                      CONFERENCE ON 13.09.2019
                    </p>
                  </Col>
                  <Col md={6}>
                    <iframe
                      src="https://www.youtube.com/embed/osWuROjgcVM?autoplay=0&amp;showinfo=0&amp;controls=0"
                      allowfullscreen
                    ></iframe>
                    <p style={{ fontSize: "12px" }}>
                      7/11 TN Bn NCC, T.D.M.N.S. College.
                    </p>
                  </Col>
                  {/*<Col md={6} >
								<iframe src="https://www.youtube.com/embed/2zoOeRrP8YU?autoplay=0&amp;showinfo=0&amp;controls=0" allowfullscreen></iframe>
								<p style={{fontSize: '12px'}} >புறநானூற்றுப் பொருண்மைகள்</p>
								</Col>*/}
                </Row>
              </div>
            </div>
            {/*<div className="col-md-3">
							{this.state.showNewsLoader ?
								<div className="bot-gal h-blog ho-event" ><h4>Circular</h4><CircleSlowLoader /></div> : 
								<Events data={this.state.upcomingList} />
							}
						</div>*/}
          </div>
        </div>
      </section>
    );
  }
}
export default NewsScroll;
