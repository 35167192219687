import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import $ from "jquery";
import { Row, Col, Carousel } from "react-bootstrap";
import { Helmet } from "react-helmet";
import API from "../../../utils/API";
import { Api } from "../../../utils/Variables";
import { getCookieField } from "../../../utils";
import { Slider, NewsScroll, SliderAnnouncement } from "../../elements";

import About from "./About";
import Circular from "./Circular";
import ImportantLinks from "./ImportantLinks";
import MarqueeAnnouncement from "./MarqueeAnnouncement";
import VisionBox from "./VisionBox";
import ManagementMembers from "./ManagementMembers";
import QuickLinks from "./QuickLinks";
import Alumni from "./alumni";
import OurRecruiter from "./ourRecruiter";
import Achivers from "./achivers";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      featureProducts: [],
    };
  }

  componentDidMount() {
    //this.loadFeatureProducts();
  }

  loadFeatureProducts() {
    try {
      API.get("v1/site/item/featured/4").then((res) => {
        if (res["data"].status == "1") {
          this.setState({ featureProducts: res["data"].data });
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <div>
        <Slider />

        <Circular />

        <VisionBox />

        {/*<NewsScroll />*/}

        {/* 	<QuickLinks /> */}

        <Alumni />

        <Achivers />

        <OurRecruiter />
      </div>
    );
  }
}
export default Home;
