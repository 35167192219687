import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card} from 'react-bootstrap';
import {Api, DT_Academics, DT_Programmes, DT_CourseMenu} from '../../../utils/Variables';
import {upperCase, groupByMultiple, makeUrl, capitalizeFirst, getAscSortOrder} from '../../../utils';
import API from '../../../utils/API';
import {CircleSlowLoader} from '../../../elements';
import axios from 'axios';

import PageHeader from '../Page/PageHeader';
import Sidebar from './Sidebar';
import Syllabus from './Syllabus';
import Programoutcomes from './Programoutcomes';
import TeachingPlan from './TeachingPlan';
import Faculty from './Faculty';
import DeptSlider from './DeptSlider';
import FacultContainer from './Faculty/FacultContainer';

const styles = {
	deptDropdwon: {
		background: '#03294e',
		border: '0px',
		color: '#fff',
	}
}

class Department extends Component{
	constructor(props){
		super(props);
		this.state = {
			courseList: [],
			employeeList: [],
			departmentEmployees: [],
			courseView: [],
			courseTypeGroup: [],
			batchList: [],
			currentCourse: [],
			materialList: [],
			actieTab: 'about',
			courseId: this.props.match.params.cid,
			pageData: [],
			facultyList: [],
			filesList: [],
			deptList: [],
		}
		
	}
	
	componentDidMount(){
		
		var hash = window.location.hash;
		
		if(hash!=undefined && hash != null && hash.length>0){
			var slug = hash.replace('#','');
			
			this.setState({actieTab: slug});
			
			//if(slug=='ematerial' || slug=='faculty'){
			if(slug=='ematerial'){
				this.loadEmployeeList();
				this.loadMaterialList();				
			}
		}
		this.loadDepartments();
		this.loadDepartmentInfo();
		this.loadPageData();
		
		//this.loadCourseInfo();
		//this.loadPageData();
	}
	
	loadDepartments(){
		try{
			
			this.setState({showLoader: true});
			API.get('v1/course/departments/all').then(res=>{
				if(res['data'].status=='1'){ 
					this.setState({deptList: res['data'].data, showLoader: false});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	

	componentWillReceiveProps(props){
		
		try{
			
			//this.setState({courseList: [], courseView: [],  batchList: [],  facultyList: [] });
						
			this.state.courseId = props.match.params.cid;
			this.setState({courseId: props.match.params.cid});
			//this.state.activeTab = 'about';
			//this.loadDepartmentInfo();
			//this.loadPageData();
			var hash = window.location.hash;
			if(hash!=undefined && hash != null && hash.length>0){
				var slug = hash.replace('#','');
				
				this.setState({actieTab: slug});
				
				//if(slug=='ematerial' || slug=='faculty'){
				if(slug=='ematerial'){
					this.loadEmployeeList();
					this.loadMaterialList();
				} 
			}
			else{
				this.setState({actieTab: 'about'});
			}
			//this.loadCourseInfo();
			this.loadDepartmentInfo();
			this.loadPageData();
		}
		catch(error){
			//console.log(error);
		}
	}
	
	loadDepartmentInfo(){
		try{
			/*if(Object.keys(this.state.courseList).length>0){
				return;
			}*/
			this.setState({showLoader: true});
			//var courseId = this.props.match.params.cid;
			//axios.get(Api.AcademicPath+'course/coursebydept/'+this.state.courseId).then(res=>{
				var form = new FormData();
				form.append('department', this.state.courseId);
				
			API.post('site/v1/course/coursebydept',form).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({
						courseList: res['data'].data,
						courseView: res['data'].data,
						batchList: res['data'].batch,
						facultyList: res['data'].employees,
						showLoader: false});
				}
				else
				{
					this.setState({showLoader: false, currentCourse: []});
				}
			});
		}
		catch(error){
			console.log(error);
			this.setState({showLoader: false});
		}
	}
	
	loadCourseInfo(){
		try{
			this.setState({showLoader: true});
			axios.get(Api.AcademicPath+'course/getdata/'+this.state.courseId).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({showLoader: false, currentCourse: res['data'].data});
				}
				else
				{
					this.setState({showLoader: false, currentCourse: []});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	loadPageData(){
		try{
			this.setState({showLoader: true});
			API.get('site/v1/department/page/'+this.state.courseId).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({pageData: res['data'].pagedata, filesList: res['data'].filelist, showLoader: false});
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	loadMaterialList(){
		try{
			
			this.setState({showLoader: true, materialList: []});
			API.get('site/v1/eresource/'+this.state.courseId).then(res=>{
				if(res['data'].status=='1')
				{
					var d = groupByMultiple(res['data'].data,function(obj){
						return[obj.staffid];
					});
					this.setState({
						materialList: d,
						showLoader: false
					});
					
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}

	loadEmployeeInfo(){
		try{
			
			this.setState({showLoader: true, employeeList: [], departmentEmployees: []});
			axios.post(Api.AcademicPath+'course/coursebydept/'+this.state.courseId).then(res=>{
				if(res['data'].status=='1')
				{
					var empList = [];
					var emp = res['data'].employees;
					var aidedEmp = [];
					var nonEmp = [];
					var mgT = [];
					if(Object.keys(emp).length>0){
						aidedEmp = emp.filter(function(obj){
							return(obj.emptype=='aided');
						});
						nonEmp = emp.filter(function(obj){
							return(obj.emptype=='non-aided' || obj.emptype=='mca');
						});
						mgT = emp.filter(function(obj){
							return(obj.emptype=='management');
						});
						
						/* var mca = emp.filter(function(obj){
							return(obj.emptype=='mca');
						});
						if(Object.keys(mca).length>0){
							mca.map((j,k)=>{
								nonEmp.push(j);
							});
						} */
					}
					
					//aidedEmp.sort(getAscSortOrder('displayorder'));
					//nonEmp.sort(getAscSortOrder('displayorder'));
										
					empList['aided'] = aidedEmp;
					empList['unaided'] = nonEmp;
					empList['management'] = mgT;
					
					
					this.setState({
						employeeList: empList,
						departmentEmployees: emp,
						showLoader: false
					});
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	getStaffName(id){
		try{
			var d = this.state.employeeList.filter(function(obj){
				return(obj.id==id);
			});
			
			return Object.keys(d).length>0 ? d[0].empname+' '+d[0].qualification : '';
		}
		catch(error){
			return '';
		}
	}
	
	listMaterialsByStaff(item){
		try{
			var rv = [];
			var d = item;/* this.state.dataList.filter(function(obj){
				return(obj.staffid==id);
			});
			*/
			d.map((item,i)=>{
				rv.push(<li key={i} style={{marginBottom: '5px', fontSize: '17px'}} >
					<a href={Api.ImageUrl + item.filepath} target="_blank" >
						{item.filetitle}
					</a>
				</li>);
			});
			
			return rv;
		}
		catch(error){
			return '';
		}
	}
	
	displayPageData(){
		try{
			
			//console.log(this.state.pageData);
			var actieTab = this.state.actieTab;
			var d = this.state.pageData.filter(function(obj){
				return(upperCase(obj.category)==upperCase(actieTab));
			});
			
			if(Object.keys(d).length>0)
			{
				return d[0]['pagecontent'];
			}
			else
				return '';
		}
		catch(error){
			return '';
		}
	}
	
	getFilesByCategory(category){
		try{
			
			var d = this.state.filesList.filter(function(obj){
				return(upperCase(obj.category)==upperCase(category));
			});
			
			return d;
		}
		catch(error){
			return '';
		}
	}
	
	getCourseFieldValue(fieldName){
		try{
			
			var d = this.state.courseList;
			return d[0][fieldName];
		}
		catch(error){
			
		}
	}
	
	loadEmployeeList(){
		try{
			
			this.setState({showLoader: true});			
			API.get('site/v1/course/faculty/'+this.state.courseId).then(res=>{
				if(res['data'].status=='1'){
					var d = groupByMultiple(res['data'].data, function(obj){
						return[obj.emptype];
					});
					this.setState({showLoader: true, employeeList: res['data'].data, employeeView: d});
				}
				else{
					this.setState({showLoader: false});					
				}
			});			
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	
	render(){
		
		/*if(this.state.isRedirect) {
			return <Redirect to={this.state.redirectUrl} />;
		}*/
		
		var dname = upperCase(this.getCourseFieldValue('departmentname'));
		var logo = this.getCourseFieldValue('logopath');
		
		return(
			<section>
			
			  <PageHeader />
				
				<div  className="container page-in pb-50 pt-30 shadow-lg  ">
					
					<Row>
					<Col md={2} >
						{/*this.state.showLoader ? <CircleSlowLoader /> : */}
							<Row>
								<Col md={12} className="p-0" >
									<div className="de-left-tit" >
									<h5 className="mmm-sidebar-menu-title"  ><span className="ion-ios7-arrow-right" style={{
												color:'#fff',
												fontSize: '20px',
												fontWeight: '600',
												marginRight: '5px',
									}} ></span> MENU</h5> 
									<hr />
									</div>
									<div className="ho-event">
										<ul className="mmm-sidebar-menu" >
										{DT_CourseMenu.map((item,i)=>{
											return <li key={i} >
													<a href={"#"+item.link}  onClick={()=>{this.setState({actieTab: item.link})}}
													className={this.state.actieTab==item.link ? 'cl__dept__menu__active' : ''} >
														{item.name}
													</a>
											</li>;
										})
										}
										</ul>
									</div>	
								</Col>
							</Row>
							
						</Col>

						<Col md={8} >
							{/*<Row>
								<Col md={12} >
									

									<ul className="nav nav-tabs" >
										{DT_CourseMenu.map((item,i)=>{
											return <li key={i}  >
													<a data-toggle="tab" href={"#"+item.link}  onClick={()=>{this.setState({actieTab: item.link})}}
													className={this.state.actieTab==item.link ? ' text-danger' : ' '} >
														{item.name}
													</a>
											</li>;
										})
										}
										</ul>
								</Col>
							</Row>	*/}
							
							{dname!=null && dname!=undefined && dname!='' && dname.length>2 ?
								<div style={{textAlign:'center', marginBottom: '-4px' }} className="con-title" > 
									
								<h2 style={{fontSize:'22px'}} >Department of {capitalizeFirst(this.getCourseFieldValue('departmentname'))}</h2>
								{logo!=null && logo!=undefined && logo!='' && logo.length>2 ? 
									<div>
										<hr />
										<img src={Api.ImageUrl+logo} style={{width: '150px'}} /> 
									</div> : <hr />}
								
							</div> : null}
							
							{this.state.showLoader ? <CircleSlowLoader />
							
							:
							<div>
								
							<div style={{width:'100%'}} >
								
								{this.state.actieTab=='about' ?  <DeptSlider deptId={this.state.courseId} /> : null}
								
								{this.state.actieTab=='about' ? 
									<div>
										<h5 className="my-15" >About</h5>
										<div dangerouslySetInnerHTML={{__html: this.displayPageData()}} />
								</div> : null}
								
								{this.state.actieTab=='courses' ? 
									<div>
										<h5 className="my-15" >Courses Offered</h5>
										<div>
											<table className="table table-bordered">
												<thead style={{background: '#f1f1f1'}} >
													<tr>
														<th>Programme  Name</th>
														<th>Programme  Type</th>
													</tr>
												</thead>
												<tbody>
												{this.state.courseView.map((item,i)=>{
													return <tr key={i} >
														<td><b>{item.type} - {item.name}</b></td>
															<td>{item.coursetype=='aided' || item.coursetype=='regular' ? 'Aided' : 'Un-Aided'}</td>
													</tr>;
												})
												}
												</tbody>
											</table>
										</div>
								</div> : null}
								
								{this.state.actieTab=='vision' ? 
									<div>
										<h5 className="my-15" >Vision & Mission</h5>
										<div dangerouslySetInnerHTML={{__html: this.displayPageData()}} />
								</div> : null}
								
								{this.state.actieTab=='faculty' ? 
									<div>
										<FacultContainer deptId={this.state.courseId} />										
								</div> : null}
								
								{this.state.actieTab=='syllabus' ? 
									<div>
										<h5 className="my-15" >Syllabus</h5>
										<div dangerouslySetInnerHTML={{__html: this.displayPageData()}} />
										<hr />
										<Syllabus files={this.getFilesByCategory('syllabus')} />
								</div> : null}
								
								
								{this.state.actieTab=='courseoutcomes' ? 
									<div>
										<h5 className="my-15" >POS & Course Outcomes</h5>
										<div dangerouslySetInnerHTML={{__html: this.displayPageData()}} />
										<hr />
										<Programoutcomes files={this.getFilesByCategory('courseoutcomes')} />
								</div> : null}
								
								{this.state.actieTab=='teachingplan' ? 
									<div>
										<h5 className="my-15" >POS & Teaching Plan</h5>
										<div dangerouslySetInnerHTML={{__html: this.displayPageData()}} />
										<hr />
										<TeachingPlan files={this.getFilesByCategory('teachingplan')} />
								</div> : null}
								
								{this.state.actieTab=='awards' ? 
									<div>
										<h5 className="my-15" >Awards</h5>
										<div dangerouslySetInnerHTML={{__html: this.displayPageData()}} />
								</div> : null}
								
								{this.state.actieTab=='highlights' ? 
									<div>
										<h5 className="my-15" >Highlights</h5>
										<div dangerouslySetInnerHTML={{__html: this.displayPageData()}} />
								</div> : null}
								
								{this.state.actieTab=='activities' ? 
									<div>
										<h5 className="my-15" >Activities</h5>
										<div dangerouslySetInnerHTML={{__html: this.displayPageData()}} />
								</div> : null}
								
								{this.state.actieTab=='alumni' ? 
									<div>
										<h5 className="my-15" >Alumni</h5>
										<div dangerouslySetInnerHTML={{__html: this.displayPageData()}} />
								</div> : null}
								
								{this.state.actieTab=='ematerial' ? 
									<div className="custom-ematerial" >
										<h5 className="my-15" >E-Material</h5>
										<Row>
											<Col md={12} >
												<ul className="collapsible mb-15 list-style-none" >
													{this.state.materialList.map((item,i)=>{
														return  <li onClick={()=>{this.setState({activeId: i})}} 
																className={this.state.activeId==i ? 'active' : ''}
																>
															<div className={this.state.activeId==i ? "collapsible-header collapsible-header-active" : "collapsible-header"} >
																<h4 style={{fontSize: '15px', fontWeight: '600'}} >{this.getStaffName(item[0].staffid)}
																	<span className="pull-right">
																		{this.state.activeId==i ? 
																			<i className="ion-android-remove"></i> : 
																			<i className="ion-android-add"></i>
																		}
																	</span>
																</h4>
															</div>
															<div 
																className={ this.state.activeId==i ? 'collapsible-body ' : ' collapsible-body hide-collapsible-body'} >
																<ul>
																	{this.listMaterialsByStaff(item)}
																</ul>
															</div>
														</li>;
													})
													}
												</ul>
											</Col>
										</Row>
								</div> : null}
							
							</div>
							
							</div>
							}
							
						</Col>
						
						<Col md={2} >
							
							<Sidebar activeMenu={DT_Programmes} activeMenuTitle="PROGRAMMES" deptList={this.state.deptList} />
							
						</Col>
					</Row>
					
				</div>
			</section>
		);
	}
	
}
export default Department;
