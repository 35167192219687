import React from "react";
import { Col, Row } from "react-bootstrap";

const styles = {
  sectionBox: {
    backgroundImage:
      "url(https://tdmnscollege.edu.in/files_list/tdmns/fm/website/bgcolor1.avif)",
    backgroundSize: "100% 128%",
  },
  missionBox: {
    padding: "0px",
  },
  shadow: {
    backgroundColor: "rgb(255 255 255 / 18%)",
    height: "250px",
    padding: "0 40px 20px 40px",
    fontSize: "20px",
    //color: "#fff",
    fontFamily: "Gellix",
  },
  shadowMotto: {
    backgroundColor: "rgb(255 255 255 / 18%)",
    height: "120px",
    padding: "0 40px 20px 40px",
    fontSize: "20px",
    fontFamily: "Gellix",
    //color: "#fff",
  },
};

const VisionBox = (props) => {
  return (
    <>
      <section className="page-section pt-40 pb-40" style={styles.sectionBox}>
        <div className="container" style={{ fontFamily: "Gellix" }}>
          <Row>
            <Col md={5}>
              <div className="home-top-cour" style={styles.missionBox}>
                <div style={styles.shadow}>
                  <h4 className="text-center  fw-bold ">Vision</h4>
                  <div style={{ lineHeight: "37px" }}>
                    Empowering the downtrodden who long to have social justice
                    in rural India through quality education and making them
                    self-reliant and divine citizens of this land.
                  </div>
                </div>
              </div>
            </Col>
            <Col md={7}>
              <div className="home-top-cour " style={styles.missionBox}>
                <div style={styles.shadow}>
                  <h4 className="text-center fw-bold ">Mission</h4>
                  <ul style={{ paddingLeft: "0px" }}>
                    <li>
                      To offer quality education at the doorsteps of the rural
                      folk.
                    </li>
                    <li>
                      To activate the uneducated rural mass into first
                      generation learners.
                    </li>
                    <li>
                      To provide social justice to the downtrodden in the
                      society through meaningful education.
                    </li>
                    <li>To educate and emancipate the rural women.</li>
                    <li>
                      To develop the rural young buds into gems of future India.
                    </li>
                    <li>
                      To culture the rural youth to lead a life with mission.
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="home-top-cour " style={styles.shadowMotto}>
                <div style={styles.shadow}>
                  <h4 className="text-center fw-bold">Motto</h4>
                  <div
                    className="text-center color-on-hover-white"
                    style={{ fontSize: "36px" }}
                  >
                    "To Foster the Divine in the Human."
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default VisionBox;
