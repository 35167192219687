import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card} from 'react-bootstrap';
import {Api, DT_Academics, DT_Programmes, DT_CourseMenu} from '../../../utils/Variables';
import {upperCase, groupByMultiple, makeUrl, capitalizeFirst, getDescSortOrder} from '../../../utils';
import API from '../../../utils/API';
import {CircleSlowLoader} from '../../../elements';

class Syllabus extends Component{
	constructor(props){
		super(props);
		this.state = {
			dataList: this.props.files,
			dataGroup: [],
			activeTab: '-1',
			showModal: true,
			dataItem: [],
		}
		
		//this.handleLinkClick = this.handleLinkClick.bind(this);
	}
	
	componentDidMount(){
		
		this.groupFiles();
	}
	
	
	groupFiles(){
		try{
			
			var data = this.props.files;
			
			var d = groupByMultiple(data, function(obj){
				return[obj.forcourse];
			});
			
			this.setState({dataGroup: d});
		}
		catch(error){
			
		}
	}

	loadAcademicLi(course){
		try{
			
			var rv = [];
			course.map((item,i)=>{
				
				var url = item.filepath.replace(Api.ImageUrl,'');
				item.fileurl = Api.ImageUrl+url;
				rv.push(<li>
				<a href={Api.ImageUrl+url} target="_blank" >
				{/*<a href="javascript:;" onClick={this.handleLinkClick.bind(this,item[0])} >*/}
					{item.filetitle}
				</a>
				</li>);
			
			});
			
			return rv;
		}
		catch(error){
			
		}
	}
	
	loadSyllabusByCourse(course){
		try{

			
			course.sort(getDescSortOrder('academicyear'));
			
			// used to hide same academic year datas
			var m = groupByMultiple(course,function(obj){
				return[obj.academicyearid];
			});
			
			// used to hide same academic year datas
			//console.log(d);
			var rv = [];
			m.map((item,i)=>{
			
				rv.push(<Col md={12} ><div className=" mb-15" >
					{item[0].academicyear}
					<ul>{this.loadAcademicLi(item)}</ul>
					</div></Col>);
			
			});
			
			return rv;
		}
		catch(error){
			console.log(error);
		}
	}
	
	handleLinkClick(item){
		
		this.setState({dataItem: item, showModal: true});
	}
	
	render(){
		
		
		return(
			 <div>
				
				<Row>
				{this.state.dataGroup.map((item,index)=>{
					
					return <Col md={12} key={index}  onClick={()=>{this.setState({activeTab: index})}} >
						<div className="accordion" id="accordionExample">
							<div className="card" >
								<div className={this.state.activeTab==index ? "card-header " : "card-header collapsible-header"} >
								{/*upperCase(item[0]['coursetype']=='regular' ? 'Aided' : 'Non-Aided')*/}
									
									{upperCase(item[0].forcourse)}
									
								<div className="" style={{float:'right'}} >
									{this.state.activeTab==index ? <span className="ion-ios7-arrow-up" style={{fontSize:'20px'}} ></span>
										: <span className="ion-ios7-arrow-down" style={{fontSize:'20px'}} ></span>
									}
									</div>
								</div>
							<div className="card-body collapsible-body cor-tim-tab" style={{display: this.state.activeTab==index ? 'block' : 'none'}} >
								<Row>
								{this.loadSyllabusByCourse(item)}
								</Row>
							</div>
							</div>
						</div>
					</Col>;
				})
				}
				</Row>
				
				
			 </div>
		);
	}
	
}
export default Syllabus;
