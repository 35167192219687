import React, { Component } from 'react';
import {Redirect, Link, NavLink} from 'react-router-dom';
import $ from 'jquery';
import Cookies from 'universal-cookie';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {getCookieField, capitalizeFirst, makeUrl} from '../../../utils';

class MobMenu extends Component{
	constructor(props){
		super(props);
		this.state = {
			menuList: [],
		}
		
		this.handleMenuClose = this.handleMenuClose.bind(this);
		this.handleMenuOpen = this.handleMenuOpen.bind(this);
	}
	
	componentDidMount(){
		try{
			
			/*$("#mobile_menu ul li a").on("click",function(){
				//console.log('oj');
				$("#mobile_menu").removeClass('ed-mm-act');
			});
			*/
			
		}
		catch(error){
			//console.log(error);
		}
	}
	
	componentWillMount(){
			
		
	}
	
	handleMenuClose(){		
	
		$(".ed-mob-menu").css({'display':'none'});
		$("#mobile_menu").css({'right':'-100%'});
	}
	
	handleMenuOpen(){		
	
		$(".ed-mm-act").css({'right':'0%'});
	}
	
	getSecondLevelMenu(items){
		var rv =[];
		var ls = [];
		//rv.push(<ul>);
		items.map((item,i)=>{
			ls.push(<li><Link to={item.menulink} onClick={this.handleMenuClose} >{item.title}</Link></li>);
		});
		rv.push(<ul>{ls}</ul>);
		return rv;
	}
	
	loadTopMenu(item){
		var rv = [];
		
		//rv.push(<li><Link to={item.menulink} >{item.title}</Link></li>);
		
		if(item.children && Object.keys(item.children).length>0){
			rv.push(<h4>{item.title}</h4>);
			rv.push(this.getSecondLevelMenu(item.children));
		}
		else{
			rv.push(<ul><li><Link to={item.menulink} onClick={this.handleMenuClose} >{item.title}</Link></li></ul>);
		}
		
		return rv;
	}
	
	render(){
		
	
		return( 
		 <div className="ed-mob-menu">
            <div className="ed-mob-menu-con">
                
                <div className="ed-mm-right">
                    <div className="ed-mm-menu">
                        <a href="javascript:;" className="ed-micon" onClick={this.handleMenuOpen} ><i className="ion-navicon-round"></i></a>
                        <div className="ed-mm-inn" id="mobile_menu"  >
						<div className="mob-menu-header" >
                            <a href="javascript:;" className="ed-mi-close" onClick={this.handleMenuClose} ><i className="ion-close-round"></i></a>
								<div className="mob-menu-titlte" >MENU</div>
							<hr className="mob-menu-title-hr" />
						</div>
							{/*<ul>
								<li><Link to="/">Home</Link></li>
							</ul>
							<h4>Programmes</h4>
							<ul>
							{DT_Programmes.map((item,i)=>{
								return <li key={i} ><Link to={item.link} >{item.name}</Link></li>;
							})
							}
							</ul>
							<h4>Syllabus</h4>
							<ul>
							{DT_Syllabus.map((item,i)=>{
								return <li key={i} ><Link to={item.link} >{item.name}</Link></li>;
							})
							}
							</ul>*/}
							<ul>
							{this.props.menus.map((item,i)=>{
								return this.loadTopMenu(item);
							})
							}
							</ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
		
		);
	}
	
}
export default MobMenu;