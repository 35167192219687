import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card} from 'react-bootstrap';
import {Api, DT_Academics, DT_Programmes, DT_CourseMenu} from '../../../utils/Variables';
import {upperCase, groupByMultiple, makeUrl, capitalizeFirst, getDescSortOrder} from '../../../utils';
import API from '../../../utils/API';
import {CircleSlowLoader} from '../../../elements';

class Programoutcomes extends Component{
	constructor(props){
		super(props);
		this.state = {
			dataList: this.props.files,
			dataGroup: [],
			activeTab: '-1',
		}
	}
	
	componentDidMount(){
		
		this.groupFiles();
	}
	
	
	groupFiles(){
		try{
			
			var data = this.props.files;
			data.sort(getDescSortOrder('forcourse'));
			var d = groupByMultiple(data, function(obj){
				return[obj.forcourse];
			});
			
			this.setState({dataGroup: d});
		}
		catch(error){
			
		}
	}

	loadSyllabusByCourse(course){
		try{

			
			course.sort(getDescSortOrder('academicyear'));
			
			// used to hide same academic year datas
			/*var m = groupByMultiple(course,function(obj){
				return[obj.academicyearid];
			});*/
			
			// used to hide same academic year datas
			//console.log(d);
			var rv = [];
			course.map((item,i)=>{
				var url = item.filepath.replace(Api.ImageUrl,'');
			rv.push(<Col md={4} ><div className="mb-15 text-center border py-10 px-10" >
				<a href={Api.ImageUrl+url} target="_blank" >
					<h5 className="pb-0" >{item.academicyear}</h5>
					<div className="pb-10" >{item.filetitle}</div>
				</a>
			</div></Col>);
			
			});
			
			return rv;
		}
		catch(error){
			
		}
	}
	
	render(){
		
		
		return(
			 <div>
				
				<Row>
				{this.state.dataGroup.map((item,index)=>{
					
					return <Col md={12} key={index}  onClick={()=>{this.setState({activeTab: index})}} >
						<div className="card" >
							<div className={this.state.activeTab==index ? "card-header" : "card-header"} >
							{/*upperCase(item[0]['coursetype']=='regular' ? 'Aided' : 'Non-Aided')*/}
								
								{upperCase(item[0].forcourse)}
								
							<div className="" style={{float:'right'}} >
								{this.state.activeTab==index ? <span className="ion-ios7-arrow-up" style={{fontSize:'20px'}} ></span>
									: <span className="ion-ios7-arrow-down" style={{fontSize:'20px'}} ></span>
								}
								</div>
							</div>
						<div className="card-body" style={{display: this.state.activeTab==index ? 'block' : 'none'}} >
							<Row>
							{this.loadSyllabusByCourse(item)}
							</Row>
						</div>
						</div>
					</Col>;
				})
				}
				</Row>
			 </div>
		);
	}
	
}
export default Programoutcomes;
