import $ from "jquery";
import React, { Component } from "react";
import { Link } from "react-router-dom";

/* import TDMNS_LOGO from '../../../assets/img/tdmnsc-1.png'; */
import TDMNS_LOGO from "../../../assets/img/finallogo2.png";
import NAAC_LOGO from "../../../assets/img/naac_since.gif";
import SINCE_1971 from "../../../assets/img/since_1971.png";
//import TDMNS_LOGO from '../../../assets/img/new-logo.jpg';

import HEADER_3 from "../../../assets/img/header-3.png";

import TopMenu from "./TopMenu";

const styles = {
  bgStyle: {
    backgroundImage: `url(${HEADER_3})`,
    backgroundSize: "100% 100%",
  },
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryList: [],
    };

    this.handleMenuOpen = this.handleMenuOpen.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleWindowScroll, true);
    /*$('.has-dropdown').mouseenter(function(){
			var $this = $(this);
			$this
				.find('.dropdown')
				.css('display', 'block')
				.addClass('animated-fast fadeInUpMenu');

		}).mouseleave(function(){
			var $this = $(this);
			$this
				.find('.dropdown')
				.css('display', 'none')
				.removeClass('animated-fast fadeInUpMenu');
		})*/
  }

  handleWindowScroll() {
    try {
      //var header = document.getElementById("top-header");
      var header = document.getElementsByClassName("cl__menu__row")[0];
      // Get the offset position of the navbar
      var sticky = header.offsetTop;

      if (window.pageYOffset > sticky) {
        header.classList.add("cl__menu__scrolled");
      } else {
        header.classList.remove("cl__menu__scrolled");
      }
    } catch (error) {}
  }

  handleMenuOpen() {
    $(".ed-mob-menu").css({ display: "block" });
    $("#mobile_menu").css({ right: "0%" });
  }

  render() {
    $("html, body").animate({ scrollTop: 0 }, "slow");

    return (
      <div className="section cl__header">
        <div style={styles.bgStyle}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 pt-2">
                <Link to="/" id="">
                  <img src={TDMNS_LOGO} alt="" style={{ maxWidth: "70%" }} />
                </Link>
              </div>
              <div className="col-md-4"></div>
              <div className="col-md-2 pt-3 d-none d-sm-block">
                <img src={NAAC_LOGO} style={{ maxWidth: "80%" }} />
              </div>
              {/* 	<div className="col-md-8 mob-50" >
						<h3 className="top-title">Tirunelveli Dakshina Mara Nadar Sangam College</h3>
						<div className="top-title2 "  >(Co-Education Institution.Estb.1971)(Accredited by NAAC with B<sup>+</sup> Grade)</div>
						<div className="top-title2"   >Affiliated to Manonmaniam Sundaranar University, Tirunelveli</div>
						<div className="top-title2"  >T.Kallikulam 627 113.</div>
					</div>
					<div className="col-md-2 mob-25 text-center">
						<Link to="/" id="" className="mob-hide" >
							<img src={GL_LOGO} alt="" style={{width: '90%'}}  />
						</Link>
						<div className="mob-show mob-menu-nav-icon" onClick={this.handleMenuOpen} >
							<i className="ion-navicon-round" ></i>
						</div>
					</div> */}
            </div>
          </div>
        </div>

        <TopMenu />
      </div>
    );
  }
}
export default Header;
