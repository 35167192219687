import React, { Component } from "react";
import { Redirect, Link, NavLink } from "react-router-dom";
import $ from "jquery";
import Cookies from "universal-cookie";
import API from "../../../utils/API";
import { Api } from "../../../utils/Variables";
import {
  getCookieField,
  capitalizeFirst,
  makeUrl,
  getls,
} from "../../../utils";

import "./index.css";
import FloatLeftButton from "./FloatLeftButton";
import { Col, Row } from "react-bootstrap";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryList: [],
    };
  }

  render() {
    return (
      <div className="">
        {/*<FloatLeftButton />*/}

        {/*<div style={{background: '#f99318'}} >
					<div className="container" >
						<div className="row pt-30 pb-10" >
							<div className="col-md-9" >
								<h4>To join in our Alumni Association please click on the link next.</h4>
							</div>
							<div className="col-md-3" >
								<a href="https://alumni.sriparasakthicollege.edu.in/" target="_blank" className="btn btn-success mb-15" >
									JOIN NOW
								</a>
							</div>
						</div>
					</div>	
				</div>*/}
        <footer id="site_footer" role="contentinfo">
          <div className="overlay">
            <div className="container pt-30 pb-10">
              <Row>
                <Col md={3}>
                  <h3>LOCATION</h3>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3947.4602534270334!2d77.64972401410142!3d8.356301701463826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b046182a8c1dcc5%3A0x2aa64f45c7d0b4e8!2sT.D.M.N.S%20College%20%2C%20T.Kallikuzham!5e0!3m2!1sen!2sin!4v1594630415950!5m2!1sen!2sin"
                    width="100%"
                    height="190px"
                    frameborder="0"
                    style={{ border: "0" }}
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                  ></iframe>
                </Col>
                <Col md={3} xs={6} sm={6}>
                  <h3>QUICK LINKS</h3>
                  <ul className="style-1">
                    <li>
                      <Link to="/courses-offered">Courses Offered</Link>
                    </li>
                    <li>
                      <Link to="/admission">Admission</Link>
                    </li>
                    <li>
                      <Link to="/academic-calendar">Academic Calendar</Link>
                    </li>
                    <li>
                      <Link to="/college">College Profile</Link>
                    </li>
                    <li>
                      <Link to="/iqac-vision-mission">IQAC</Link>
                    </li>
                    <li>
                      <Link to="/clubs-cells">Clubs & Cells</Link>
                    </li>
                    <li>
                      <Link to="/payment-policy">Payment Policy</Link>
                    </li>
                  </ul>
                </Col>
                <Col md={3} xs={6} sm={6}>
                  <h3>RESEARCH LINKS</h3>
                  <ul className="style-1">
                    <li>
                      <a href="https://scholar.google.co.in/" target="_blank">
                        Google Scholar
                      </a>
                    </li>
                    <li>
                      <a href="https://ieeexplore.ieee.org/" target="_blank">
                        IEEE Xplore
                      </a>
                    </li>
                    <li>
                      <a href="https://www.csir.res.in/" target="_blank">
                        CSIR
                      </a>
                    </li>
                    <li>
                      <a href="https://www.drdo.gov.in/" target="_blank">
                        DRDO
                      </a>
                    </li>
                    <li>
                      <a href="http://dbtindia.gov.in/" target="_blank">
                        DBT India
                      </a>
                    </li>
                    <li>
                      <a href="http://icssr.org/" target="_blank">
                        ICSSR
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
              <div className="row ">
                <div className="col-md-3 "></div>
                <div className="col-md-2 col-sm-4 col-xs-6 "></div>

                <div className="col-md-2 col-sm-4 col-xs-6 "></div>

                <div className="col-md-2 col-sm-4 col-xs-6 ">
                  <h3>USEFUL LINKS</h3>
                  <ul className="style-1">
                    <li>
                      <a href="http://www.msuniv.ac.in/" target="_blank">
                        MS University
                      </a>
                    </li>
                    <li>
                      <a href="http://www.ugc.ac.in/" target="_blank">
                        UGC
                      </a>
                    </li>
                    <li>
                      <a href="http://www.naac.gov.in/" target="_blank">
                        NAAC
                      </a>
                    </li>
                    <li>
                      <a href="http://epgp.inflibnet.ac.in/" target="_blank">
                        Open Education Resource
                      </a>
                    </li>
                    <li>
                      <a href="https://swayam.gov.in/" target="_blank">
                        Online Courses
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="col-md-3 ">
                  <h3>GET IN TOUCH</h3>
                  <ul className="footer-address">
                    <li>
                      {/*<i className="ion-ios7-location"></i>*/}
                      {getls("site", "address")}
                    </li>
                    <li>
                      {getls("site", "city")}, {getls("site", "state")}
                    </li>
                    <li>
                      {getls("site", "country")} - {getls("site", "pincode")}
                    </li>
                    <li>
                      <i className="ion-android-call"></i>{" "}
                      {getls("site", "phone")} (Office)
                    </li>
                    <li>
                      <i className="ion-android-call"></i> 04637-220650
                      (Principal)
                    </li>
                    <li>
                      <i className="ion-email"></i> {getls("site", "email")}
                    </li>
                    <li>
                      <a href="https://www.hitwebcounter.com" target="_blank">
                        <img
                          src="https://hitwebcounter.com/counter/counter.php?page=7354318&style=0006&nbdigits=7&type=page&initCount=8520"
                          title="Web Counter"
                          Alt="counter free"
                          border="0"
                          style={{
                            width: "125px",
                            border: "2px solid #f4623a",
                            marginTop: "3px",
                          }}
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="row copyright px-50">
            <div className="col-md-12 text-center">
              <div className="text-center center-block">
                <a
                  href="https://www.facebook.com/profile.php?id=100092690727928&mibextid=gik2fB"
                  target="_blank"
                >
                  <i
                    id="social-fb"
                    className="fa fa-facebook-square fa-3x social"
                  ></i>
                </a>
                <a
                  href="Check out TDMNS_College (@tdmnsc85351): https://x.com/tdmnsc85351?t=054hh0WEkZu2ebELBfQ3xg&s=08"
                  target="_blank"
                >
                  <i
                    id="social-tw"
                    className="fa fa-twitter-square fa-3x social"
                  ></i>
                </a>
                <a
                  href="https://x.com/tdmnsc85351?t=IJBCVGg4iNWjj6-t2qh72w&s=09"
                  target="_blank"
                >
                  <i
                    id="social-tw"
                    className="fa fa-instagram fa-3x social"
                  ></i>
                </a>
                {/* 	<a href="" target="_blank" ><i id="social-gp" className="fa fa-google-plus-square fa-3x social"></i></a> */}
                <a
                  href="https://www.youtube.com/@tdmnscollegenews799?si=wXVoiwo2zl7vL-Vi"
                  target="_blank"
                >
                  <i
                    id="social-gp"
                    className="fa fa-youtube-square fa-3x social"
                  ></i>
                </a>
                <a href="mailto:tdmnscollege@gmail.com">
                  <i
                    id="social-em"
                    className="fa fa-envelope-square fa-3x social"
                  ></i>
                </a>
              </div>

              <div>
                <small className="block">
                  &copy; {new Date().getFullYear()}{" "}
                  {getls("site", "companyname")}. All Rights Reserved.
                </small>
                <small className="block">
                  Designed by{" "}
                  <a
                    href="http://peacesoft.in"
                    target="_blank"
                    style={{ color: "#fff" }}
                  >
                    Peace Soft
                  </a>
                </small>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
export default Footer;
