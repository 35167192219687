import React, { Component } from 'react';
import {Redirect, Link, NavLink} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card} from 'react-bootstrap';
import {Api, DT_Academics, DT_QuickLinks} from '../../../utils/Variables';
import {upperCase, groupByMultiple} from '../../../utils';

class Sidebar extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
	}
	
	componentDidMount(){
		
	}
	
	
	render(){
		
		
		return(
			  <div className="" >
			 
				<Row>
					<Col md={12} >			
						<h4>Quick Links</h4>
						<hr style={{marginBottom: '10px'}} />
						<div className="">
							<ul className="mmm-sidebar-menu" >
							{DT_QuickLinks.map((item,i)=>{
								return <li key={i} >
									<NavLink to={item.link} activeClassName="active" >
										{item.name}
									</NavLink>
								</li>;
							})
							}
							</ul>
						</div>
					</Col>
				</Row>
		</div>
						
		);
	}
	
}
export default Sidebar;
