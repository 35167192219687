import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {getCookieField} from '../../../utils';


class ImportantLinks extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	
	componentDidMount(){
	
	}
	
	
	render(){
		
		
		return(
			<section className="home_links" >
				<div className="container" >
					<div className="row pt-30 pb-30" >
					{/*<div className="col-md-12" >
							<h2 className="text-center mb-20" >Important Links</h2>
					</div>*/}
						<div className="col-md-6" >
							<div className="text-center py-10  shadow-lg" style={{backgroundColor: '#f78f11'}} >
								<div className="mx-10 py-10" style={{border: '1px solid #fff'}} >
									<h3 style={{marginBottom: '5px'}} >Our Vision</h3>
									<div style={{color: '#fff'}} className="vision_box" >To transform rural girls with fossilized rural mind-set into women with potential through academic, value and career oriented teaching, ensuring equity, accessibility and excellence, thereby contribute to national development.</div>
								</div>
							</div>
						</div>
						
						<div className="col-md-6" >
							<div className="text-center py-10 shadow-lg" style={{backgroundColor: '#f78f11'}} >
								<div className="mx-10 py-10 " style={{border: '1px solid #fff'}} >
									<h3 style={{marginBottom: '5px'}} >Our Mission</h3>
									<div style={{color: '#fff'}} className="text-left" >
										<ul>
											<li>To design academic programs and activities to empower and uplift rural women.</li>
											<li>To prepare the stakeholders with employable potential thereby make them self dependent and self Confident.</li>
											<li>To inculcate civic sense and social commitment in stakeholders and mould them as responsible citizens.</li>
											<li>To create an intellectual community in and around our area.</li>
											<li>To mould the stakeholders with holistic personality traits.</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						
						<div className="col-md-12 py-10"></div>
						
						<div className="col-md-3" >
							<div className="text-center py-10 shadow-lg" style={{backgroundColor: '#f78f11'}} >
								<div className="mx-10 py-10" style={{border: '1px solid #fff'}} >
									<h3 style={{marginBottom: '5px'}} >ICT Resources</h3>
									<div style={{color: '#000'}} >E-Content/E-Materials</div>
								</div>
							</div>
						</div>
						
						<div className="col-md-3" >
							<div className="text-center py-10 shadow-lg" style={{backgroundColor: '#f78f11'}} >
								<div className="mx-10 py-10" style={{border: '1px solid #fff'}} >
									<h3 style={{marginBottom: '5px'}} >Placement Cell</h3>
									<div style={{color: '#000'}} >Click to View</div>
								</div>
							</div>
						</div>
						
						<div className="col-md-3" >
							<div className="text-center py-10 shadow-lg" style={{backgroundColor: '#f78f11'}} >
								<div className="mx-10 py-10" style={{border: '1px solid #fff'}} >
									<h3 style={{marginBottom: '5px'}} >Grievances</h3>
									<div style={{color: '#000'}} >Click to View</div>
								</div>
							</div>
						</div>
						
						
						<div className="col-md-3" >
							<div className="text-center py-10 shadow-lg" style={{backgroundColor: '#f78f11'}} >
								<div className="mx-10 py-10" style={{border: '1px solid #fff'}} >
									<h3 style={{marginBottom: '5px'}} >Online Courses</h3>
									<div style={{color: '#000'}} >Click to View</div>
								</div>
							</div>
						</div>
						
					</div>
				</div>
		  </section>
		);
	}
	
}
export default ImportantLinks;
