import $ from "jquery";
import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { removeBothSlash } from "../../../utils";

import "./index.css";

class BootstrapMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuList: this.props.options,
      htmlStrTxt: [],
    };

    this.handleMenuHover = this.handleMenuHover.bind(this);
    this.handleMenuOut = this.handleMenuOut.bind(this);

    this.handleMenuClick = this.handleMenuClick.bind(this);
  }

  componentDidMount() {}

  handleMenuHover(mId) {
    try {
      //document.getElementById(mId).style.display = 'block';
      var ele = document.getElementById(mId);
      $(ele).fadeIn();
    } catch (error) {
      console.log(error);
    }
  }

  handleMenuOut(mId) {
    try {
      var ele = document.getElementById(mId);
      $(ele).fadeOut();
    } catch (error) {
      console.log(error);
    }
  }

  handleMenuClick(mId) {
    try {
      //document.getElementById(mId).style.display = 'block';
      /*var ele = document.getElementById(mId);
			$(ele).css('display','block');
			$(ele).css('left','200px');*/
    } catch (error) {
      console.log(error);
    }
  }

  renderSubMenu(children, mId) {
    var rv = [];
    if (children && children.length > 0) {
      rv.push(
        <ul
          className="dropdown-menu multi-level"
          role="menu"
          aria-labelledby="dropdownMenu"
          id={mId}
        >
          {this.renderMenuItems(children)}
        </ul>
      );
      return rv;
    }
  }

  renderMenuItems(items) {
    var rv = [];
    items.map((item, i) => {
      const { menulink, title, children, id } = item;

      const mId = "i__sm__" + id;
      const childList = this.renderSubMenu(children, mId);
      rv.push(
        <li
          className={
            childList != undefined && childList.length > 0 ? "dropdown" : ""
          }
        >
          {childList != undefined && childList.length > 0 ? (
            <NavLink
              exact
              to={"/" + removeBothSlash(menulink)}
              key={`link-${i}-`}
              className="dropdown-toggle"
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={this.handleMenuClick.bind(this, mId)}
            >
              {title}
            </NavLink>
          ) : (
            <NavLink
              exact
              to={"/" + removeBothSlash(menulink)}
              key={`link-${i}-`}
            >
              {title}
            </NavLink>
          )}
          {childList}
        </li>
      );
    });
    return rv;
  }

  getFourthLevel(menus) {
    var rv = [];
    menus.map((item, i) => {
      rv.push(
        <li className="">
          <NavLink exact to={"/" + removeBothSlash(item.menulink)}>
            {item.title}{" "}
          </NavLink>
        </li>
      );
    });
    return rv;
  }

  getThridLevel(menus) {
    var rv = [];
    menus.map((item, i) => {
      var child = this.getFourthLevel(item.children);

      child && child.length > 0
        ? rv.push(
            <li className="dropdown-submenu">
              <a
                href="javascript:;"
                className="dropdown-toggle"
                data-toggle="dropdown"
              >
                {item.title}{" "}
              </a>
              <ul className="dropdown-menu">{child}</ul>
            </li>
          )
        : rv.push(
            <li className="">
              <NavLink exact to={"/" + removeBothSlash(item.menulink)}>
                {item.title}{" "}
              </NavLink>
            </li>
          );
    });
    return rv;
  }

  getSecondLevel(menus) {
    var rv = [];
    menus.map((item, i) => {
      var child = this.getThridLevel(item.children);
      child && child.length > 0
        ? rv.push(
            <li className="dropdown-submenu">
              <a
                href="javascript:;"
                className="dropdown-toggle"
                data-toggle="dropdown"
              >
                {item.title}{" "}
              </a>
              <ul className="dropdown-menu">{child}</ul>
            </li>
          )
        : rv.push(
            <li className="nav-item">
              <NavLink
                exact
                to={"/" + removeBothSlash(item.menulink)}
                className="sub-nav-link"
              >
                {item.title}{" "}
              </NavLink>
            </li>
          );
    });
    return rv;
  }

  getMenuTopItem(menus) {
    var rv = [];
    menus.map((item, i) => {
      var child = this.getSecondLevel(item.children);
      var mLink = removeBothSlash(item.menulink);
      child && child.length > 0
        ? rv.push(
            <li className="nav-item dropdown">
              <a
                href="javascript:;"
                className="nav-link dropdown-toggle"
                id="navbarDropdown"
              >
                {item.title}{" "}
              </a>
              <ul className="dropdown-menu">{child}</ul>
            </li>
          )
        : rv.push(
            <li className="nav-item">
              {mLink == "" || mLink == "/" ? (
                <Link
                  to={"/" + removeBothSlash(item.menulink)}
                  className="nav-link js-scroll-trigger"
                >
                  {item.title}
                </Link>
              ) : (
                <NavLink
                  exact
                  to={"/" + removeBothSlash(item.menulink)}
                  className="nav-link js-scroll-trigger"
                >
                  {item.title}{" "}
                </NavLink>
              )}
            </li>
          );
    });
    return rv;
  }

  render() {
    return (
      <div
        className="collapse navbar-collapse show cs-menu"
        id="navbarResponsive"
      >
        <ul className="navbar-nav ml-auto mr-auto my-2 my-lg-0">
          {this.getMenuTopItem(this.props.options)}
        </ul>
      </div>
    );
  }
}
export default BootstrapMenu;
