import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const Alumni = (props) => {
  const [windowSize, setWindowSize] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      // Perform actions on window resize
      //setWindowSize([window.innerWidth, window.innerHeight]);
      setWindowSize(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setWindowSize(window.innerWidth);
  }, []);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: windowSize && windowSize < 500 ? 1 : 4,
    slidesToScroll: windowSize && windowSize < 500 ? 1 : 4,
    autoplay: true,
  };

  const alumniData = [
    {
      name: "Mr.A.R. Gladstone Pushparaj, I.A.S., ",
      designation: "Collector, Ranipet District",
      img: "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/alumni/mrgladstone.jpg",
    },
    {
      name: "Mr. Narayanan",
      designation: "Deputy Collector ",
      img: "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/alumni/mrnarayanan.jpg",
    },
    {
      name: "Dr.V. Soma Sundaram,",
      designation: "Velan Hospital, Radhapuram",
      img: "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/alumni/drsomasundaram.jpg",
    },
    {
      name: "Dr.C. Selvaraj",
      designation:
        "Professor & Head, Department of Mathematics, Periyar University, Salem ",
      img: "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/alumni/drcselvaraj.jpg",
    },
    {
      name: "Dr.A. Joseph Kennedy",
      designation:
        "Professor and Head, School of Mathematics, Central University, Pondicherry",
      img: "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/alumni/drjoseph.jpg",
    },
    {
      name: "Dr.V. Antony Vijesh ",
      designation:
        "Associate Professor, Indian Institute of Technology, Indore",
      img: "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/alumni/drvantony.jpg",
    },
    {
      name: "Dr.V. Muthulakshmi ",
      designation: "Associate Professor, Periyar University, Salem ",
      img: "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/alumni/drvmuthulakshmi.jpg",
    },
    {
      name: "Dr.V. Murugan ",
      designation: "Professor, National Institute of Technology, Karnataka",
      img: "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/alumni/drvmurugan.jpg",
    },
    {
      name: "Dr.K. Pazhani,",
      designation: "Former Principal",
      img: "https://tdmnscollege.edu.in/files_list/tdmns/fm/website/alumni/mrkpalani.jpg",
    },
  ];

  const getCard = (item) => {
    return (
      <div className="px-3 home-top-cour ps__alumni">
        <Card className="">
          <Card.Img src={item.img} />
          <Card.Body>
            <Row className="">
              <Col md={12}>
                <div
                  style={{
                    height: "100px",
                    overflowY: "auto",
                    fontSize: "14px",
                  }}
                >
                  <h4 style={{ fontSize: "17px" }}>{item.name}</h4>
                  <div>{item.designation}</div>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    );
  };

  return (
    <>
      <section>
        <div className="container mb-4">
          <h2 className="text-center py-4">Illustrious Alumni</h2>
          <Slider {...settings}>
            {alumniData.map((item, i) => getCard(item, i))}
          </Slider>
        </div>
      </section>
    </>
  );
};

export default Alumni;
