import React, { Component } from 'react';
import $ from 'jquery';
import ModalPdfViewer from '../../ModalWindow/ModalPdfViewer';
import {Api, Site} from '../../../../utils/Variables';

import NOIMAGE from '../../../../assets/img/noimage.jpg';

const styles = {
	header: {
	    padding: '6px',
		borderBottom: '1px solid green',
		background: 'linear-gradient(45deg, #0e6dca, #0fded5)',
		color: '#fff',
	},
	btn: {
	    padding: '7px',
		fontSize: '12px',
		height: '30px',
		background: 'linear-gradient(45deg, #174982, #22abb9)',
	}
}

class FacultyRow extends Component{
	constructor(props){
		super(props);
		this.state = {
			showModal: false,
			fileLink: '',
		}
		this.noFileAlert = this.noFileAlert.bind(this);
	}
	
	noFileAlert(){
		alert('Sorry file not found');
	}
	
	handleCvClick(item){
		try{
			
			//this.setState({fileLink: item, showModal: true});
			this.props.fileClick(item);
		}
		catch(error){ }
	}
	
	getLinkButton(fileLink){
		var rv = [];
		if(fileLink == null || fileLink == ''){
			rv.push(<button type="button" className="btn btn-success" style={styles.btn} onClick={this.noFileAlert} >
				View Profile
			</button>);
		}
		else{
			/* rv.push(<a href={fileLink} target="_blank" className="btn btn-success" style={styles.btn} >
				View Profile
			</a>); */
			rv.push(<a href="javascript:;"  className="btn btn-success" style={styles.btn} onClick={this.handleCvClick.bind(this,fileLink)} >
				View Profile
			</a>);
		}
		return rv;
	}
	
	render(){
	
		const staff = this.props.data;
				
		/*return(
			 <div className="card card-primary"  >
				<div className="card-body" style={styles.header} >
					<h4>{staff.empname} {staff.qualification}</h4>
				</div>
				<div className="card-body" style={{padding: '10px' }} >
					<div className="row py-0 px-0" >
						<div className="col-md-3" >
							<img src={(staff.photo.length > 3) ? staff.photo : NOIMAGE} style={{width:'120px', borderRadius: '0px 30px', boxShadow: '2px 3px 1px 2px #d0cece' }} />
						</div>
						<div className="col-md-9" >
							<div className="pl-30"  >
								<table width="100%" >
									<tr>
										<td height="30" ><b>{staff.empname}</b></td>
									</tr>
									<tr>
										<td height="30" >{staff.qualification}</td>
									</tr>
									<tr>
										<td height="30" >{staff.email}</td>
									</tr>
									<tr>
										<td height="30" >
											<a href="javascript:;" className="btn btn-warning btn-sm mt-15 btn_view_profile" >
												View Profile
											</a>
										</td>
									</tr>
								</table>
							</div>
						</div>
					</div>
				</div>
			 </div>
		);*/
		
		return(
			<tr>
				<td>{this.props.sno}</td>
				<td style={{lineHeight: '35px'}} >
					<h5 style={{textTransform: 'uppercase'}} >{staff.empname}</h5>
					{staff.qualification}
					<br />
					{staff.email}
					<br />
					{this.getLinkButton(staff.pdffile)}
					
				</td>
				<td align="center" >
					<center>
						<img src={(staff.photo.length > 3) ? Api.ImageUrl+staff.photo : NOIMAGE} style={{width:'120px', borderRadius: '0px', boxShadow: '1px 1px 1px 1px #d0cece' }} />
					</center>
				</td>
			</tr>
		);
	}
	
}
export default FacultyRow;
