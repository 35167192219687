import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import $ from "jquery";
import { Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import API from "../../../utils/API";
import { Api } from "../../../utils/Variables";
import { getCookieField } from "../../../utils";
import ANNOUNCE_IMG from "../../../assets/img/announce_speaker.png";

import MarqueeAnnouncement from "./MarqueeAnnouncement";
import WOW from "wowjs";

class Circular extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: 1200,
    };
  }

  componentDidMount() {
    new WOW.WOW().init();
    window.addEventListener("resize", this.resize.bind(this));
  }

  componentWillUnmount() {
    // you need to unbind the same listener that was binded.
    window.removeEventListener("resize", this.resize, false);
  }

  resize() {
    var w = window.innerWidth;
    var c = 6;
    if (w <= 500) c = 2;
    else if (w <= 700) c = 3;
    else if (w <= 900) c = 3;
    else if (w <= 1000) c = 6;
    this.setState({ windowWidth: w, loaderCount: c, scrollCount: c });
  }

  render() {
    return (
      <section className="page-section bg-light py-40" id="about">
        <div className="container">
          <div className="row justify-content-center">
            <div
              className="col-lg-8 text-justify "
              style={{
                lineHeight: this.state.windowWidth <= 700 ? "26px" : "36px",
              }}
            >
              <h4
                style={{
                  lineHeight: "44px",
                  fontSize: "32px",
                  fontFamily: "Gellix",
                  fontWeight: "700",
                }}
              >
                Welcome to TDMNS College
              </h4>

              <div
                style={{
                  fontFamily: "Gellix",
                  fontSize: "26px",
                  lineHeight: "40px",
                  textAlign: "left",
                }}
              >
                Tirunelveli Dakshina Mara Nadar Sangam College is located in the
                vast site donated by the people of T.Kallikulam. The first
                building was declared open by the then Honourable Minister
                <b>Si. Pa. Adithanar on the 5th July 1971</b>, the day on which
                the College started functioning.{" "}
                {/*Later, the management constructed
                three more blocks at different times. Two waiting halls for
                girls were also constructed. The College, which was started as
                an approved College in July 1971, with just 100 students and 10
                teaching faculty members, has been showing a steady growth. Now
                it is a full-fledged first grade College, affiliated to
                Manonmaniam Sundaranar University, with 4 Post Graduate Courses,
                fourteen Under Graduate Courses, one Ph.D., course and five
              career oriented courses.*/}
                {"  "}
                <Link to="/college" className="" style={{ fontSize: "17px" }}>
                  Read More...
                </Link>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                style={{ background: "transparent", padding: "15px" }}
                className="cs-announcement-top wow slideInRight"
              >
                <h4 style={{ textAlign: "center" }} className="ps__announce_h4">
                  <img
                    src={ANNOUNCE_IMG}
                    className="blink-image"
                    style={{ width: "45px", marginRight: "15px" }}
                  />
                  Announcements
                </h4>

                <MarqueeAnnouncement />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Circular;
